<template>
  <component :is="isComponent" :class="classes" v-bind="$attrs" v-on="$listeners">
    <slot />
  </component>
</template>

<script>
import Span from "./BaseText/Span.vue";
import Division from "./BaseText/Division.vue";
import Paragraph from "./BaseText/Paragraph.vue";

export default {
  name: "BaseText",

  components: {
    Span,
    Division,
    Paragraph,
  },

  props: {
    type: {
      type: String,
      required: false,
      default: "span",
    },

    color: {
      type: [String, Number],
      required: false,
      default: "lowEmphasis",
    },

    body: {
      type: String,
      required: false,
      default: "1",
    },

    overline: {
      type: Boolean,
      required: false,
      default: false,
    },

    caption: {
      type: Boolean,
      required: false,
      default: false,
    },

    bold: {
      type: Boolean,
      required: false,
      default: false,
    },

    italic: {
      type: Boolean,
      required: false,
      default: false,
    },

    uppercase: {
      type: Boolean,
      required: false,
      default: false,
    },

    subTitle1: {
      type: Boolean,
      required: false,
      default: false,
    },

    subTitle2: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  computed: {
    isComponent() {
      switch (this.type) {
        case "div":
          return Division;

        case "p":
          return Paragraph;

        default:
          return Span;
      }
    },

    bodyClass() {
      return `body-${this.body}`;
    },

    colorClass() {
      return `${this.color}--text`;
    },

    boldClass() {
      return "font-weight-bold";
    },

    overlineClass() {
      return "text-overline";
    },

    italicClass() {
      return "font-italic";
    },

    captionClass() {
      return "text-caption";
    },

    uppercaseClass() {
      return "text-uppercase";
    },

    subTitle1Class() {
      return "text-subtitle-1";
    },

    subTitle2Class() {
      return "text-subtitle-2";
    },

    isNotBody() {
      return this.overline || this.caption || this.uppercase || this.subTitle1 || this.subTitle2;
    },

    baseClasses() {
      return [this.colorClass, this.bold && this.boldClass, this.italic && this.italicClass];
    },

    defaultClasses() {
      if (this.isNotBody) return [];

      return [this.bodyClass];
    },

    customClasses() {
      if (!this.isNotBody) return [];

      return [
        this.overline && this.overlineClass,
        this.caption && this.captionClass,
        this.uppercase && this.uppercaseClass,
        this.subTitle1 && this.subTitle1Class,
        this.subTitle2 && this.subTitle2Class,
      ];
    },

    classes() {
      return [...this.baseClasses, ...this.customClasses, ...this.defaultClasses];
    },
  },
};
</script>
