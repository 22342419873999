import apiClient from "@/api/modules/base";
import ROUTE from "./routes";

/**
 * @namespace countries
 */

/**
 * Retrieve country list
 *
 * @memberOf countries
 * @method
 * @param {object} params
 * @param {number} params.page
 * @param {number} params.perPage
 * @param {string} params.sortBy
 * @param {boolean} params.sortDesc
 * @param {string} params.search
 * @returns {Promise<AxiosResponse<any>>}
 */
export const listCountries = (params) => apiClient.get(ROUTE.COUNTRIES.LIST, { params });

/**
 * Show specified country
 *
 * @memberOf countries
 * @method
 * @param {object} params
 * @param {number} params.countryId
 * @returns {Promise<AxiosResponse<any>>}
 */
export const showCountry = ({ countryId }) =>
  apiClient.get(ROUTE.COUNTRIES.SHOW, { params: { countryId } });
