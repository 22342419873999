<template>
  <v-file-input
    :label="fieldLabel"
    filled
    v-model="textFieldValue"
    :rules="validation"
    v-bind="$attrs"
    v-on="$listeners"
    :error-messages="errorMessages"
    truncate-length="15"
    show-size
    :prepend-icon="null"
    :loading="loading"
    :disabled="disabled"
  >
    <template #prepend-inner v-if="icon">
      <v-icon dense class="mr-2">{{ icon }}</v-icon>
    </template>
  </v-file-input>
</template>

<script>
import formValidation from "@/mixins/formValidation";

export default {
  name: "BaseFileInput",

  mixins: [formValidation],

  props: {
    value: {
      type: undefined,
      required: true,
      default: null,
    },

    label: {
      type: String,
      required: true,
      default: "",
    },

    rules: {
      type: Array,
      required: false,
    },

    icon: {
      type: String,
      required: false,
      default: "far fa-upload",
    },

    required: {
      type: Boolean,
      default: false,
      required: false,
    },

    errorMessages: {
      type: Array,
      default: null,
    },

    mail: {
      type: Boolean,
      required: false,
      default: false,
    },

    loading: {
      type: Boolean,
      required: false,
      default: false,
    },

    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  data() {
    return {
      textFieldValue: this.value,
    };
  },

  computed: {
    validation() {
      if (this.rules) return this.rules;
      if (this.mail && this.required) return this.requiredRules.concat(this.emailRules);
      if (this.mail) return this.emailRules;
      if (this.required) return this.requiredRules;

      return undefined;
    },

    fieldLabel() {
      if (this.required) return `${this.$t(this.label)} *`;

      return this.$t(this.label);
    },

    isDisabled() {
      return !this.formValidation || !this.value || this.disabled;
    },
  },

  watch: {
    value(value) {
      this.textFieldValue = value;
    },

    textFieldValue(value) {
      this.$emit("input", value);
    },
  },
};
</script>
