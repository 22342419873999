<template>
  <span @click="goTo" v-bind="$attrs" v-on="$listeners"><slot /></span>
</template>
<script>
export default {
  name: "nav-item",

  props: {
    navItem: {
      type: Object,
      required: true,
      default: () => ({
        to: null,
        url: null,
        command: null,
        path: null,
      }),
    },
  },

  methods: {
    async goTo() {
      if ("to" in this.navItem || "path" in this.navItem) {
        try {
          await this.$router.push(this.navItem.to || this.navItem.path);
        } catch {
          return;
        }
      }

      if ("url" in this.navItem) {
        if ("target" in this.navItem) {
          if (this.navItem.target === "_blank") {
            return window.open(this.navItem.url);
          }
        }
        location.assign(this.navItem.url);
      }

      if ("command" in this.navItem) {
        this.navItem.command();
      }
    },
  },
};
</script>
