<template>
  <v-dialog ref="dialog" v-model="modal" persistent width="290px">
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        v-model="formattedDate"
        :label="fieldLabel"
        readonly
        v-bind="attrs"
        v-on="on"
        filled
        :rules="validation"
        :error-messages="errorMessages"
      >
        <template v-slot:prepend-inner>
          <v-icon dense class="mr-2">far fa-clock</v-icon>
        </template>
      </v-text-field>
    </template>

    <v-time-picker format="24hr" v-model="localTime">
      <v-spacer></v-spacer>

      <v-btn text color="primary" @click="saveTime">
        {{ $t("BASE.DATE_PICKER_BUTTON_LABEL_OK") }}
      </v-btn>
    </v-time-picker>
  </v-dialog>
</template>
<script>
import formValidation from "@/mixins/formValidation";
import dateFilter from "@mixins/dateFilter";
import dayjs from "dayjs";

export default {
  name: "BaseTimePicker",

  mixins: [formValidation, dateFilter],

  data() {
    return {
      localTime: null,
      menu: false,
      modal: false,
      maxDate: null,
      minDate: null,
    };
  },

  props: {
    label: {
      type: String,
      require: true,
      default: "",
    },

    value: {
      type: Date,
      required: false,
    },

    required: {
      type: Boolean,
      default: false,
      required: false,
    },

    rules: {
      type: Array,
      required: false,
    },

    errorMessages: {
      type: Array,
      default: null,
    },
  },

  computed: {
    formattedDate: {
      get() {
        return this.localTime;
      },
      set() {
        // Do nothing
      },
    },

    validation() {
      if (this.rules) return this.rules;
      if (this.required) return this.requiredRules;

      return undefined;
    },

    fieldLabel() {
      if (this.required) return `${this.$t(this.label)} *`;

      return this.$t(this.label);
    },

    newDateTime() {
      const date = dayjs(this.value);
      const HM = this.localTime.split(":");

      return date.hour(HM[0]).minute(HM[1]);
    },
  },

  watch: {
    value() {
      this.localTime = this.value ? this.time(this.value) : this.time(new Date());
    },
  },

  methods: {
    saveTime() {
      this.$emit("input", new Date(this.newDateTime));
      this.modal = false;
    },

    setCurrentTime() {
      this.localTime = this.value ? this.time(this.value) : this.time(new Date());
    },
  },

  mounted() {
    this.setCurrentTime();
  },
};
</script>
