import { mapActions } from "vuex";
import regex from "@/data/regex";
import { isEmpty } from "lodash";

export default {
  data() {
    return {
      validationTitle: null,
      validationErrors: {},
      formValidation: null,
    };
  },

  computed: {
    zipRules() {
      return [(v) => regex.zipCodeGermany.test(v) || this.$t("FORM_VALIDATION.ZIP")];
    },

    emailRules() {
      return [(v) => regex.email.test(v) || this.$t("FORM_VALIDATION.EMAIL")];
    },

    membershipNumberRules() {
      return [(v) => regex.membershipNumber.test(v) || this.$t("FORM_VALIDATION.BWS_MEMBERSHIP")];
    },

    seminarNumberRules() {
      return [(v) => regex.seminarNumber.test(v) || this.$t("FORM_VALIDATION.SEMINAR_NUMBER")];
    },

    requiredRules() {
      return [(v) => !!v || this.$t("FORM_VALIDATION.REQUIRED")];
    },

    mfaTokenRules() {
      return [(v) => regex.mfaToken.test(v) || this.$t("FORM_VALIDATION.MFA")];
    },

    passwordRules() {
      return [(v) => regex.passwordPolicy.test(v) || this.$t("FORM_VALIDATION.PASSWORD_ALL")];
    },

    urlRules() {
      return [(v) => regex.url.test(v) || this.$t("FORM_VALIDATION.URL")];
    },
  },

  methods: {
    ...mapActions("notifications", ["notify"]),

    maxFileSize(maxFileSize) {
      return [
        (v) => {
          if (v) {
            if (v.size > parseInt(maxFileSize * 1000, 10)) {
              return this.$t("FORM_VALIDATION.MAX_FILE_SIZE", {
                maxFileSize: parseInt(maxFileSize / 1000, 10),
              });
            }
          }

          return true;
        },
      ];
    },

    maxChars(maxValue) {
      return [
        (v) => {
          if (maxValue === 0) return true;
          if (!v) return true;

          return (
            v.length <= parseInt(maxValue, 10) || this.$t("FORM_VALIDATION.MAX_CHARS", { maxValue })
          );
        },
      ];
    },

    setValidationErrors(err, showMessage = true) {
      if (err.response && err.response.status !== 500 && err.response.status !== 401) {
        const { data } = err.response;
        const { errors } = data;
        this.validationErrors = errors;

        if (showMessage) {
          const errorMessages = Object.values(errors).join(" ");

          this.notify({
            type: "error",
            message: errorMessages,
          });
        }
      }
    },

    hasErrors(key) {
      if (!isEmpty(this.validationErrors) && key in this.validationErrors) {
        return this.validationErrors[key];
      }

      return null;
    },

    getErrors(key) {
      if (!isEmpty(this.validationErrors) && key in this.validationErrors) {
        return this.validationErrors[key];
      }

      return null;
    },

    validate() {
      this.$refs.form.validate();
    },

    reset() {
      this.$refs.form.reset();
    },

    resetValidation() {
      this.$refs.form.resetValidation();
    },
  },
};
