/**
 * @module vuex-authUser
 */

/**
 *
 * Retrieve data of auth user
 *
 * @param {object} vuex
 * @returns {Promise}
 */
const showAuthUserPermissions = async ({ commit }) => {
  try {
    const { data } = await window.$axios.authUser.showAuthUserPermissions();
    commit("SET_AUTH_USER_PERMISSIONS", data.data);

    return Promise.resolve();
  } catch (err) {
    return Promise.reject(err);
  }
};

export default showAuthUserPermissions;
