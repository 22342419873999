import apiClient from "@/api/modules/base";
import ROUTE from "./routes";

/**
 * @namespace authUser
 */

/**
 * Retrieve profile data
 *
 * @memberOf authUser
 * @method
 * @returns {Promise<AxiosResponse<any>>}
 */
export const showAuthUserProfile = () => apiClient.get(ROUTE.AUTH_USER.PROFILE.SHOW);

/**
 * Update auth user profile
 *
 * @memberOf authUser
 * @method
 * @param {*} params
 * @returns {Promise<AxiosResponse<any>>}
 */
export const updateAuthUserProfile = (params) =>
  apiClient.put(ROUTE.AUTH_USER.PROFILE.UPDATE, params);

/**
 * Retrieve data of currently logged in user
 *
 * @memberOf authUser
 * @method
 * @returns {Promise<AxiosResponse<any>>}
 * @author Dominik Niemann <dominik@polargold.de>
 */
export const showAuthUser = () => apiClient.get(ROUTE.AUTH_USER.SHOW);

/**
 * Retrieve permission data of currently logged in user
 *
 * @memberOf authUser
 * @method
 * @returns {Promise<AxiosResponse<any>>}
 * @author Dominik Niemann <dominik@polargold.de>
 */
export const showAuthUserPermissions = () => apiClient.get(ROUTE.AUTH_USER.PERMISSIONS.SHOW);

/**
 * Create user delete request
 *
 * @memberOf authUser
 * @method
 * @param {object} payload
 * @param {string} payload.comment
 * @returns {Promise<AxiosResponse<any>>}
 *
 * @author Oliver Vollborn <oliver@polargold.de
 */
export const createProfileDeleteRequest = ({ comment }) =>
  apiClient.post(ROUTE.AUTH_USER.PROFILE.DELETE_REQUEST.CREATE, {
    comment,
  });

/**
 * Delete user delete request
 *
 * @memberOf authUser
 * @method
 * @returns {Promise<AxiosResponse<any>>}
 * @author Oliver Vollborn <oliver@polargold.de
 */
export const deleteProfileDeleteRequest = () =>
  apiClient.delete(ROUTE.AUTH_USER.PROFILE.DELETE_REQUEST.DELETE);

/**
 * Change password of auth user
 *
 * @memberOf authUser
 * @method
 * @param {object} params
 * @param {string} params.password
 * @param {string} params.passwordConfirmation
 * @param {string} params.token
 * @returns {Promise<AxiosResponse<any>>}
 */
export const changePassword = ({
  password = null,
  passwordConfirmation = null,
  token = null,
} = {}) =>
  apiClient.put(ROUTE.AUTH_USER.CHANGE_PASSWORD, {
    password,
    passwordConfirmation,
    token,
  });

/**
 * Create MFA Token
 *
 * @memberOf authUser
 * @method
 * @returns {Promise<AxiosResponse<any>>}
 */
export const createMFAToken = () => apiClient.post(ROUTE.AUTH_USER.CREATE_MFA_TOKEN);

/**
 *
 * @memberOf authUser
 * @method
 * @param {number}perPage
 * @param {number} page
 * @param {string[]}?}sortBy
 * @param {boolean[]?} sortDesc
 * @returns {Promise<AxiosResponse<any>>}
 */
export const listPersonChange = ({ perPage = 10, page = 1, sortBy, sortDesc } = {}) =>
  apiClient.get(ROUTE.AUTH_USER.PERSON_CHANGE.LIST, {
    params: { perPage, page, sortBy, sortDesc },
  });

/**
 *
 * @memberOf authUser
 * @method
 * @param {string} payload.salutation
 * @param {string} payload.firstName
 * @param {string} payload.lastName
 * @param {string} payload.street
 * @param {string} payload.zipCode
 * @param {string} payload.city
 * @param {string} payload.emailMain
 * @param {string} payload.emailSub
 * @param {string} payload.phone
 * @param {string} payload.mobile
 *
 * @returns {Promise<AxiosResponse<any>>}
 */
export const createPersonChange = (payload) =>
  apiClient.post(ROUTE.AUTH_USER.PERSON_CHANGE.CREATE, payload);

/**
 * Shows person data related to user
 * @returns {Promise<AxiosResponse<{id: null | number, companyId: null | number, erpKey: string, salutation: null | string, firstName: null | string, lastName: null | string, street: null | string, zipCode: null | string, city: null | string, emailMain: null | string, emailSub: null | string, phone: null | string, mobile: null | string, isMember: null | string, isSpeaker: boolean, speakerJobTitle: null | string, speakerDescription: null | string}>>}
 */
export const showPerson = () => apiClient.get(ROUTE.AUTH_USER.PERSON_CHANGE.SHOW);
