export default {
  AUTH: {
    LOGIN: "/auth/login",
    REQUEST_NEW_PASSWORD: "/auth/forgot-password/request",
    CREATE_NEW_PASSWORD: "/auth/forgot-password/update",
    CREATE_NEW_PASSWORD_SHOW: "/auth/forgot-password/show",
    VALIDATE_TOKEN: "/auth-user/check",
    ACTIVATION: "/auth/activation",
    USER_INVITATIONS: {
      SHOW: "/auth/invitations/show",
    },
    REGISTER: "/auth/invitations",
    REQUEST_ACCOUNT: "/auth/account-request",
    MULTI_FACTOR: {
      GUEST: "/authentication-token/unauthorized",
      AUTH: "/authentication-token/authorized",
    },
  },
};
