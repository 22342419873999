<template>
  <v-icon :color="status ? 'success' : 'error'" small>fas fa-circle</v-icon>
</template>

<script>
export default {
  name: "BaseStatusIndicator",

  props: {
    status: {
      type: Boolean,
      require: true,
      default: false,
    },
  },
};
</script>
