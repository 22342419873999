/**
 * @module vuex-authUser
 */

/**
 *
 * Retrieve data of auth user
 *
 * @param {object} vuex
 * @returns {Promise}
 */
const showAuthUser = async ({ commit }) => {
  try {
    const { data } = await window.$axios.authUser.showAuthUser();
    commit("SET_AUTH_USER", data.data);
    commit("SET_AUTH_USER_PERMISSIONS", data.permissions);

    return Promise.resolve();
  } catch (err) {
    return Promise.reject(err);
  }
};

export default showAuthUser;
