<template>
  <v-dialog ref="dialog" v-model="modal" persistent width="290px">
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        v-model="formattedDate"
        readonly
        v-bind="attrs"
        v-on="on"
        filled
        :rules="validation"
        :error-messages="errorMessages"
        hide-details
        flat
        solo
        background-color="gray200"
      >
        <template v-slot:prepend-inner>
          <v-icon dense class="mr-2">far fa-calendar-alt</v-icon>
        </template>
      </v-text-field>
    </template>

    <v-date-picker v-model="localDate" scrollable :max="maxDate" :min="minDate">
      <v-spacer />

      <v-btn text color="primary" @click="saveDate">
        {{ $t("BASE.DATE_PICKER_BUTTON_LABEL_OK") }}
      </v-btn>
    </v-date-picker>
  </v-dialog>
</template>
<script>
import formValidation from "@/mixins/formValidation";
import dayjs from "dayjs";

export default {
  name: "BaseFilterDatePicker",

  mixins: [formValidation],

  data() {
    return {
      localDate: dayjs().format("YYYY-MM-DD"),
      menu: false,
      modal: false,
      maxDate: null,
      minDate: null,
    };
  },

  props: {
    value: {
      type: Date,
      required: false,
      default: new Date(dayjs()),
    },

    max: {
      type: Date,
      required: false,
      default: null,
    },

    min: {
      type: Date,
      required: false,
      default: null,
    },

    required: {
      type: Boolean,
      default: false,
      required: false,
    },

    rules: {
      type: Array,
      required: false,
    },

    errorMessages: {
      type: Array,
      default: null,
    },
  },

  computed: {
    formattedDate: {
      get() {
        return dayjs(this.value).format("DD.MM.YYYY");
      },
      set() {
        // Do nothing
      },
    },

    validation() {
      if (this.rules) return this.rules;
      if (this.required) return this.requiredRules;

      return undefined;
    },
  },

  watch: {
    value(value) {
      this.localDate = dayjs(value).format("YYYY-MM-DD");
    },

    min(value) {
      if (!value) {
        this.minDate = value;
      } else {
        this.minDate = dayjs(value).format("YYYY-MM-DD");
      }
    },

    max(value) {
      if (!value) {
        this.maxDate = value;
      } else {
        this.maxDate = dayjs(value).format("YYYY-MM-DD");
      }
    },
  },

  methods: {
    saveDate() {
      this.$emit("input", new Date(dayjs(this.localDate)));
      this.modal = false;
    },
  },

  mounted() {
    this.localDate = dayjs(this.value).format("YYYY-MM-DD");
  },
};
</script>
