import apiClient from "@/api/modules/base";
import ROUTE from "./routes";

/**
 * Retrieve a list with seminar participants
 *
 * @method
 * @memberOf userManagement
 * @param {object} params
 * @param {number} params.perPage
 * @param {number} params.page
 * @param {string} params.sortBy
 * @param {boolean} params.sortDesc
 * @param {string} params.search
 * @param {boolean} params.isUserAssigned
 * @returns {Promise<AxiosResponse<any>>}
 */
export const listSeminarParticipants = (params) =>
  apiClient.get(ROUTE.SEMINAR_PARTICIPANTS.INDEX, { params });

/**
 * Retreive specified seminar participant
 *
 * @method
 * @memberOf userManagement
 * @param {object} params
 * @param {number|null} params.seminarParticipantId
 * @param {string|null} params.navisionNumber
 * @returns {Promise<AxiosResponse<any>>}
 */
export const showSeminarParticipant = (params) =>
  apiClient.get(ROUTE.SEMINAR_PARTICIPANTS.SHOW, { params });
