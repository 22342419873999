import router from "@/router";
import store from "@/store";
import { computed } from "vue";

export default () => {
  const initializeRouteAuthenticationCheck = async () => {
    const userIsAuthenticated = computed(() => store.getters["auth/isAuthenticated"]);
    const pagesWithUnauthorizedAccess = [
      "Login",
      "RequestAccount",
      "RequestAccountSuccess",
      "RequestNewPassword",
      "RequestNewPasswordSuccess",
      "CreateNewPassword",
      "UserInvitationFinish",
    ];

    const redirectUser = async (currentName) => {
      if (!currentName) return;

      const currentPageIsPublic = pagesWithUnauthorizedAccess.some((name) => currentName === name);

      if (currentPageIsPublic && userIsAuthenticated.value) {
        try {
          await router.push({ name: "Dashboard" });
        } catch {
          return;
        }
      }

      if (!currentPageIsPublic && !userIsAuthenticated.value) {
        try {
          await router.push({ name: "Login" });
        } catch {
          return;
        }
      }
    };

    await redirectUser(router.currentRoute.name);

    router.beforeResolve(async (to, from, next) => {
      await redirectUser(to.name);

      next();
    });
  };

  return { initializeRouteAuthenticationCheck };
};
