<template>
  <div class="notification-bar">
    <v-fade-transition group>
      <Message v-for="(message, index) of messages" :key="index" :message="message" />
    </v-fade-transition>
  </div>
</template>

<script>
import { mapState } from "vuex";
import Message from "./partials/Message.vue";

export default {
  name: "NotificationSnackbar",

  components: {
    Message,
  },

  computed: {
    ...mapState(["notifications"]),

    messages() {
      return this.notifications.messages;
    },
  },
};
</script>

<style lang="scss" scoped>
.notification-bar {
  position: fixed;
  bottom: 0;
  width: 100%;
}
</style>
