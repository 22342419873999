export default {
  STRING_REPLACER: {
    NO_VALUE: "Please provide a value",
    NO_STRING: "Please provide a string with placeholder",
    NO_PLACEHOLDER: "No placeholder detected",
  },

  LOCAL_STORAGE: {
    NO_KEY: "Please define a key to store value to",
    NO_VALUE: "Please provide a value",
  },

  CURRENCY_HELPER: {
    NO_VALUE: "Please provide a value",
  },

  DATE_HELPER: {
    NO_VALUE: "Please provide a value",
  },

  CACHING_HELPER: {
    NO_VALUE: "Please provide a state object",
  },

  PERMISSION_HELPER: {
    NO_ARRAY: "Arguments must be array",
    NO_VALUE: "Arguments must not be empty",
  },

  INITIALS_HELPER: {
    NO_VALUES: "Please provide first and last name",
    NO_STRING: "Values must be of type string",
  },

  MEMBERSHIP_HELPER: {
    NO_STRING: "Value must be of type string",
  },
};
