<template>
  <v-row justify="end" align="center">
    <v-col cols="auto">
      <AccountNav />
    </v-col>
  </v-row>
</template>
<script>
import AccountNav from "@components/Layout/NavBar/partials/MetaNav/partials/AccountNav/index.vue";

export default {
  name: "main-nav",

  components: {
    AccountNav,
  },
};
</script>
