import Vue from "vue";
import storageNames from "@/data/storageNames";
import { mapActions } from "vuex";
import baseComponentsRegistration from "@/helper/baseComponentsRegistration";
import App from "@/App.vue";
import router from "@/router";
import store from "@/store";
import vuetify from "@/plugins/vuetify";
import "@fortawesome/fontawesome-free/css/all.css";
import "@fortawesome/fontawesome-pro/css/all.css";
import "@/assets/fonts/fonts.scss";
import "@/styles/generalUtils.scss";
import i18n from "./i18n";
import "@/api";
import initSentry from "@helper/initSentry";
import initGoogleTagManager from "@helper/initGoogleTagManager";
import initStoreSubscriber from "@helper/initStoreSubscriber";

// Register base components
baseComponentsRegistration(Vue);

// Disable vue.js notice in browser
Vue.config.productionTip = false;

// Init store caching
initStoreSubscriber(store);

// Init Google tag manager
initGoogleTagManager(Vue);

// Init sentry
initSentry(Vue, router);

new Vue({
  router,
  store,
  vuetify,
  i18n,
  methods: {
    ...mapActions(["initializeState"]),
    ...mapActions("seo", ["setMetaData"]),
  },

  mounted() {
    // Load store data from local storage
    this.initializeState();

    this.setMetaData();

    // Sync browser tabs
    window.addEventListener("storage", (event) => {
      if (event.key === storageNames.VUEX_STORE) {
        this.initializeState();
      }
    });
  },
  render: (h) => h(App),
}).$mount("#app");
