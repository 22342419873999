import dayjs from "dayjs";

export default {
  methods: {
    date(value) {
      if (!value) return "";

      return dayjs(value).format("DD.MM.YYYY");
    },

    dateShort(value) {
      if (!value) return "";

      return dayjs(value).format("DD.MM.YY");
    },

    dateShorter(value) {
      if (!value) return "";

      return dayjs(value).format("DD.MM.");
    },

    time(value) {
      if (!value) return "";

      return dayjs(value).format("HH:mm");
    },

    dateTime(value) {
      if (!value) return "";

      return dayjs(value).format("DD.MM.YYYY, HH:mm");
    },

    year(value) {
      if (!value) return "";

      return dayjs(value).format("YYYY");
    },

    customDate(value, format) {
      if (!value) return "";

      return dayjs(value).format(format);
    },
  },
};
