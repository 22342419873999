<template>
  <div class="text-center">
    <v-menu
      offset-y
      :nudge-bottom="40"
      min-width="200"
      max-width="320"
      content-class="user-menu white"
    >
      <template v-slot:activator="{ on, attrs }">
        <div v-bind="attrs" v-on="on">
          <user-info></user-info>
        </div>
      </template>
      <account-menu-content></account-menu-content>
    </v-menu>
  </div>
</template>

<script>
import UserInfo from "@components/Layout/NavBar/partials/UserInfo.vue";
import AccountMenuContent from "@components/Layout/NavBar/partials/MetaNav/partials/AccountNav/partials/AccountMenuContent.vue";

export default {
  name: "AccountNav",

  components: {
    UserInfo,
    AccountMenuContent,
  },
};
</script>
