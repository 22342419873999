<template>
  <v-btn
    v-bind="$attrs"
    v-on="$listeners"
    depressed
    outlined
    color="gray400"
    :disabled="disabled"
    :loading="loading"
    :height="buttonHeight"
  >
    <v-icon left v-if="iconLeft" small color="lowEmphasis">
      {{ iconName ? `${iconName}` : "far fa-chevron-right" }}
    </v-icon>
    <span :class="['buttonLabel lowEmphasis--text', { 'text--disabled': disabled }]"><slot /></span>
    <v-icon right v-if="iconRight" small color="lowEmphasis">
      {{ iconName ? `${iconName}` : "far fa-chevron-right" }}
    </v-icon>
  </v-btn>
</template>

<script>
export default {
  name: "BaseButtonTertiary",

  props: {
    disabled: {
      type: Boolean,
      default: false,
    },

    iconRight: Boolean,

    iconLeft: Boolean,

    iconName: {
      type: String,
      default: undefined,
    },

    loading: {
      type: Boolean,
      default: false,
    },

    small: {
      type: Boolean,
      default: true,
    },

    large: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    buttonHeight() {
      const sizes = ["48px", "58px", "36px"];

      if (this.small) return sizes[0];
      if (this.large) return sizes[1];

      return this.$vuetify.breakpoint.mdAndDown ? sizes[0] : sizes[1];
    },
  },
};
</script>
<style lang="scss" scoped>
.buttonLabel {
  margin-top: 3px;
}
</style>
