<template>
  <div v-if="showContent">
    <slot />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import hasPermission from "@helper/hasPermission";

export default {
  name: "BaseFrontdoor",

  props: {
    keepOut: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  computed: {
    ...mapGetters("auth", ["isAuthenticated"]),
    ...mapGetters("authUser", ["permissionAreLoaded", "userIsLoaded"]),

    showContent() {
      if (this.keepOut && !this.isAuthenticated && this.hasRouteAccess) return true;

      if (
        this.isAuthenticated &&
        this.hasRouteAccess &&
        this.permissionAreLoaded &&
        this.userIsLoaded
      )
        return true;

      return false;
    },

    hasRouteAccess() {
      if ("permissionNames" in this.$route.meta || "permissionProps" in this.$route.meta) {
        return hasPermission({
          permissionNames: this.$route.meta.permissionNames || [],
          permissionProps: this.$route.meta.permissionProps || [],
        });
      }

      if ("permissionNames" in this.$route.meta && !("permissionProps" in this.$route.meta)) {
        return hasPermission({
          permissionNames: this.$route.meta.permissionNames,
        });
      }

      if (!("permissionNames" in this.$route.meta) && "permissionProps" in this.$route.meta) {
        return hasPermission({
          permissionProps: this.$route.meta.permissionProps,
        });
      }

      return true;
    },
  },

  methods: {
    redirect() {
      if (this.keepOut && this.isAuthenticated) {
        this.$router.push({ name: "Dashboard" });
      }

      if (!this.isAuthenticated && !this.keepOut) {
        this.$router.push({ name: "Login" });
      }
    },
  },

  created() {
    this.redirect();
  },

  updated() {
    this.redirect();
  },
};
</script>
