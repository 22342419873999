import apiClient from "@/api/modules/base";
import ROUTE from "./routes";

/**
 * @namespace navision
 */

/**
 * Retrieve a list with imports
 *
 * @memberOf navision
 * @method
 * @param {object} params
 * @param {number} params.page
 * @param {number} params.perPage
 * @param {string} params.sortBy
 * @param {boolean} params.sortDesc
 * @param {string} params.search
 * @param {string} params.objectType
 * @param {string} params.failedAtFrom
 * @param {string} params.failedAtTo
 * @param {string} params.importType
 * @returns {Promise<AxiosResponse<any>>}
 */
export const listImports = (params) => apiClient.get(ROUTE.IMPORTS.LIST, { params });

/**
 * Dispatch single import
 *
 * @memberOf navision
 * @method
 * @param {object} params
 * @param {number} params.bwsNavisionImportId
 * @returns {Promise<AxiosResponse<any>>}
 */
export const dispatchSingleImport = ({ bwsNavisionImportId }) =>
  apiClient.post(ROUTE.IMPORTS.DISPATCH.SINGLE, { bwsNavisionImportId });

/**
 * Delete single import
 *
 * @memberOf navision
 * @method
 * @param {object} params
 * @param {number} params.bwsNavisionImportId
 * @returns {Promise<AxiosResponse<any>>}
 */
export const deleteSingleImport = ({ bwsNavisionImportId }) =>
  apiClient.delete(ROUTE.IMPORTS.DELETE, { data: { bwsNavisionImportId } });
