import { computed, ref } from "vue";
import store from "@/store";

export default () => {
  const tokenValidator = ref();
  const userIsAuthenticated = computed(() => store.getters["auth/isAuthenticated"]);
  const token = store.getters["auth/accessToken"];

  const validateToken = async () => {
    if (!token) return;

    const { data } = await window.$axios.auth.validateToken();

    if (!data) return;
    if (data.authenticated) return;

    await store.dispatch("auth/logoutUser");
  };

  const setTokenValidator = async () => {
    tokenValidator.value = setInterval(
      async () => {
        await validateToken();
      },
      process.env.VUE_APP_REFRESH_INTERVAL
        ? parseInt(process.env.VUE_APP_REFRESH_INTERVAL, 10)
        : 600000
    );
  };

  const removeTokenValidator = () => {
    if (!tokenValidator.value) return;

    clearInterval(tokenValidator.value);
  };

  const initializeTokenValidator = async () => {
    if (!userIsAuthenticated) return;

    await validateToken();
    await setTokenValidator();
  };

  return { initializeTokenValidator, removeTokenValidator };
};
