import saveAccessToken from "./actions/saveAccessToken";
import logoutUser from "./actions/logoutUser";
import resetAuth from "./actions/resetAuth";
import typeCastHelper from "@/helper/typeCastHelper";

export const namespaced = true;

export const state = {
  isAuthenticated: false,
  accessToken: null,
  refreshToken: null,
};

export const getters = {
  isAuthenticated: (state) => state.isAuthenticated && state.accessToken !== null,
  accessToken: (state) => state.accessToken,
  isInDebugMode: () => typeCastHelper(process.env.VUE_APP_DEBUG),
};

export const mutations = {
  SAVE_ACCESS_TOKEN(state, { accessToken, refreshToken }) {
    if (!!accessToken && !!refreshToken) {
      state.isAuthenticated = true;
      state.accessToken = accessToken;
      state.refreshToken = refreshToken;
    }
  },

  LOG_OUT_USER(state) {
    state.isAuthenticated = false;
    state.accessToken = null;
    state.refreshToken = null;
  },
};

export const actions = {
  saveAccessToken,
  logoutUser,
  resetAuth,
};
