export default {
  USER_INVITATIONS: {
    INDEX: "/management/invitations",
    SHOW: "/management/invitations/show",
    STORE: "/management/invitations",
    UPDATE: "/management/invitations",
    DELETE: "/management/invitations",
    RESEND: "/management/user-invitations/resend",
  },
};
