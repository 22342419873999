export default {
  SEMINAR_SPEAKER_CONTRACTS: {
    CONTRACT: {
      LIST: "/speakers/contract",
    },
    CONTRACT_ACTIONS: {
      LIST: "/speakers/contract-action",
      POST: "/speakers/contract-action",
    },
    SEMINAR_SPEAKER_CONTRACT_STATUSES: {
      SHOW: "/seminar-management/seminar-speaker-statuses/user",
    },

    CHANGE_REQUEST: {
      STORE: "/speakers/contract-action",
      TYPES: {
        LIST: "/speakers/contract-action",
      },
      REASONS_OF_CANCELATION: {
        LIST: "/speakers/contract-reason",
      },
    },

    SEMINAR_SPEAKER_STATUS: {
      SHOW: "/seminar-management/seminar-speaker-statuses/user",
    },
  },
};
