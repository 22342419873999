/* eslint-disable no-dupe-class-members */
export default class ImageUri {
  #image;
  imageData;
  #blobData;

  constructor(imageData) {
    this.imageData = imageData;
    this.#image = new Image();
    this.#blobData = this.#createBlob();
  }

  #createBlob() {
    const decodedImage = Buffer.from(this.imageData, "base64");

    return new Blob([decodedImage]);
  }

  #createUri() {
    return URL.createObjectURL(this.#blobData);
  }

  async #createBase64Uri() {
    const reader = new FileReader();
    reader.readAsDataURL(this.#blobData);
    reader.onload = () => Promise.resolve(reader.result);
    reader.onerror = (error) => Promise.reject(error);
  }

  create() {
    this.#image.url = this.#createUri();

    return this.#image.url;
  }

  async createBase64() {
    const uri = await this.#createBase64Uri();

    return uri;
  }
}
