import initialsHelper from "@helper/initialsHelper";
import { showPerson } from "@/api/modules/authUser";

export const namespaced = true;

export const state = {
  person: null,
};

export const getters = {
  hasPersonData: (state) => !!state.person,
  personData: (state) =>
    !!state.person && {
      ...state.person,
      fullName: `${state.person.firstName} ${state.person.lastName}`,
      initials: initialsHelper(state.person.firstName, state.person.lastName),
    },
};

export const mutations = {
  SAVE_PERSON_DATA(state, personData) {
    state.person = personData;
  },
};

export const actions = {
  async retrievePersonData({ commit }) {
    try {
      const { data } = await showPerson();
      commit("SAVE_PERSON_DATA", data.data);
    } catch (err) {
      console.error(err);
      this.hasUserProfile = false;
    }
  },
};
