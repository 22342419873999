import apiClient from "@/api/modules/base";
import ROUTE from "./routes";

/**
 * @namespace notifications
 */

/**
 * Show list of notifications
 *
 * @memberOf notifications
 * @method
 * @param {object} params
 * @returns {Promise<AxiosResponse<any>>}
 */
export const listNotifications = (params) => apiClient.get(ROUTE.NOTIFICATIONS.LIST, { params });

/**
 * Show raw notification data
 *
 * @memberOf notifications
 * @method
 * @param {*} params
 * @returns {Promise<AxiosResponse<any>>}
 */
export const showNotification = (params) => apiClient.get(ROUTE.NOTIFICATIONS.SHOW, { params });

/**
 * Show pre rendered notification template
 *
 * @memberOf notifications
 * @method
 * @param {*} params
 * @returns {Promise<AxiosResponse<any>>}
 */
export const renderNotification = (params) => apiClient.get(ROUTE.NOTIFICATIONS.RENDER, { params });

/**
 * Delete specified notification
 *
 * @memberOf notifications
 * @method
 * @param {*} params
 * @returns {Promise<AxiosResponse<any>>}
 */
export const deleteNotification = (params) =>
  apiClient.delete(ROUTE.NOTIFICATIONS.DELETE, { data: params });

/**
 * Delete all notification
 *
 * @memberOf notifications
 * @method
 * @returns {Promise<AxiosResponse<any>>}
 */
export const deleteAllNotifications = () => apiClient.delete(ROUTE.NOTIFICATIONS.DELETE_ALL);

/**
 * Mark single notification as reas
 *
 * @memberOf notifications
 * @method
 * @param {*} params
 * @returns {Promise<AxiosResponse<any>>}
 */
export const markAsReadSingle = (params) =>
  apiClient.put(ROUTE.NOTIFICATIONS.MARK_AS_READ_SINGLE, params);

/**
 * Mark all notifications as read
 *
 * @memberOf notifications
 * @method
 * @param {*} params
 * @returns {Promise<AxiosResponse<any>>}
 */
export const markAsReadAll = (params) =>
  apiClient.put(ROUTE.NOTIFICATIONS.MARK_AS_READ_ALL, params);

/**
 * Show unread notifications count
 *
 * @memberOf notifications
 * @method
 * @returns {Promise<AxiosResponse<any>>}
 */
export const showUnreadCount = () => apiClient.get(ROUTE.NOTIFICATIONS.UNREAD_COUNT);
