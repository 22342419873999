import { render, staticRenderFns } from "./BaseSeminarLocation.vue?vue&type=template&id=4e59fb95"
import script from "./BaseSeminarLocation.vue?vue&type=script&lang=js"
export * from "./BaseSeminarLocation.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports