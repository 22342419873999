import router from "@router";
import { sortBy } from "lodash";
import { nextTick, onMounted, ref } from "vue";
import hasPermission from "@helper/hasPermission";

/**
 * @module compositionAPI
 */

/**
 * Compile menu items
 *
 * @method
 * @param {object} params
 * @param {string} params.menuName
 * @returns {object} { items }
 */
export default ({ menuName } = {}) => {
  const items = ref([]);
  if (!menuName) return;

  const filterRouteByMenuName = (route) => {
    if (!("meta" in route)) return false;
    if (!("menuNames" in route.meta)) return false;

    return route.meta.menuNames.includes(menuName);
  };

  const initializeMenuItems = () => {
    const routesByMenuName = router.getRoutes().filter((route) => filterRouteByMenuName(route));

    const routeParents = routesByMenuName
      .filter((route) => route.parent.name === "Home" || !route.parent.name)
      .filter((route) => filterByPermission(route));

    routeParents.forEach((routeParent) => {
      const routeEntry = { ...routeParent, children: [] };
      const children = routesByMenuName
        .filter((route) => route.parent.name === routeParent.name)
        .filter((route) => filterByPermission(route));

      routeEntry.children = [...children];

      items.value.push(routeEntry);
    });

    sortMenuItems();
  };

  const sortMenuItems = () => {
    items.value = sortBy(items.value, ["meta.order"]);
  };

  const filterByPermission = (routeEntry) => {
    const hasPermissionNames = "permissionNames" in routeEntry.meta;
    const hasPermissionProps = "permissionProps" in routeEntry.meta;
    const hasNoPermissionDefinition = !hasPermissionProps && !hasPermissionNames;

    if (hasNoPermissionDefinition) return true;

    const permissionNames = routeEntry.meta.permissionNames;
    const permissionProps = routeEntry.meta.permissionProps;

    return hasPermission({ permissionNames, permissionProps });
  };

  onMounted(async () => {
    await nextTick(() => {
      initializeMenuItems();
    });
  });

  return { items };
};
