<template>
  <BaseNavigationSwitch :navItem="menuItem" class="clickable">
    <BaseHover v-model="hover">
      <v-row :class="{ gray100: hover, 'rounded mt-5 mb-6 mx-6': !menuItem.separator }" no-gutters>
        <v-col cols="12">
          <v-divider v-if="menuItem.separator"></v-divider>
          {{ $t(menuItem.meta.label) }}
        </v-col>
      </v-row>
    </BaseHover>
  </BaseNavigationSwitch>
</template>

<script>
export default {
  name: "AccountMenuItem",

  props: {
    menuItem: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      hover: false,
    };
  },
};
</script>
