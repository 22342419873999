<template>
  <h6 v-bind="$attrs" v-on="$listeners">
    <slot />
  </h6>
</template>

<script>
export default {
  name: "H6",
};
</script>
