/**
 * @module vuex-navigation
 */

/**
 * Toggle side navigation
 *
 * @param {object} vuex
 * @returns {Promise}
 */
const switchSideNav = async ({ commit }) => {
  try {
    await commit("SWITCH_TRANSITION_STATE", true);
  } finally {
    commit("SWITCH_SIDE_NAV");
  }
};

export default switchSideNav;
