<template>
  <div class="base-divider" :style="style" :class="margin"></div>
</template>

<script>
export default {
  name: "BaseDivider",

  props: {
    margin: {
      type: String,
      required: false,
      default: "my-8",
    },
  },

  computed: {
    style() {
      return {
        backgroundColor: this.$vuetify.theme.themes.light.gray300,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.base-divider {
  border: none;
  height: 1px;
  max-width: 100%;
}
</style>
