import apiClient from "@/api/modules/base";
import ROUTE from "./routes";

/**
 * Retrieve list with seminar type mappings
 *
 * @method
 * @memberOf seminarManagement
 * @param {object} params
 * @param {number} params.page
 * @param {number} params.perPage
 * @param {string} params.sortBy
 * @param {boolean} params.sortDesc
 * @param {string} params.search
 * @returns {Promise<AxiosResponse<any>>}
 */
export const listSeminarTypeMappings = (params) =>
  apiClient.get(ROUTE.SEMINAR_TYPE_MAPPINGS.LIST, { params });

/**
 * Show specified seminar type mapping
 *
 * @method
 * @memberOf seminarManagement
 * @param {object} params
 * @param {number} params.seminarTypeMappingId
 * @returns {Promise<AxiosResponse<any>>}
 */
export const showSeminarTypeMapping = (params) =>
  apiClient.get(ROUTE.SEMINAR_TYPE_MAPPINGS.SHOW, { params });

/**
 * Delete speicified seminar type mapping
 *
 * @method
 * @memberOf seminarManagement
 * @param {object} params
 * @param {number} params.seminarTypeMappingId
 * @returns {Promise<AxiosResponse<any>>}
 */
export const deleteSeminarTypeMapping = (params) =>
  apiClient.delete(ROUTE.SEMINAR_TYPE_MAPPINGS.DELETE, { data: params });

/**
 * Update specified seminar type mapping
 *
 * @method
 * @memberOf seminarManagement
 * @param {object} params
 * @param {number} params.seminarTypeMappingId
 * @param {number} params.seminarTypeId
 * @param {number} params.navisionEventAssignmentCode
 * @param {string} params.navisionDescription
 * @returns {Promise<AxiosResponse<any>>}
 */
export const updateSeminarTypeMapping = (params) =>
  apiClient.put(ROUTE.SEMINAR_TYPE_MAPPINGS.UPDATE, params);

/**
 * Create seminar type mapping
 *
 * @method
 * @memberOf seminarManagement
 * @param {object} params
 * @param {number} params.seminarTypeId
 * @param {number} params.navisionEventAssignmentCode
 * @param {string} params.navisionDescription
 * @returns {Promise<AxiosResponse<any>>}
 */
export const createSeminarTypeMapping = (params) =>
  apiClient.post(ROUTE.SEMINAR_TYPE_MAPPINGS.CREATE, params);
