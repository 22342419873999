import showAuthUser from "./actions/showAuthUser";
import resetAuthUser from "./actions/resetAuthUser";
import showAuthUserPermissions from "./actions/showAuthUserPermissions";

export const namespaced = true;

export const state = {
  user: {
    id: null,
    isSpeaker: false,
    email: null,
  },
  permissions: [],
  permissionsAreInitialized: false,
};

export const getters = {
  userIsLoaded: (state) => !!state.user.id,
  permissionAreLoaded: (state) => state.permissionsAreInitialized,
  user: (state) => state.user,
  userId: (state) => state.user.id,
  userPermissions: (state) => state.permissions.map((permission) => permission.name) || [],
  isSpeaker: (state) => !!state.user.seminarSpeaker,
};

export const mutations = {
  CHANGE_STATUS(state, status) {
    state.status = status;
  },

  SET_AUTH_USER(state, authUser) {
    state.user = authUser;
  },

  async SET_AUTH_USER_PERMISSIONS(state, permissions) {
    if (permissions !== undefined) {
      state.permissions = permissions;
      state.permissionsAreInitialized = true;
    }
  },

  RESET_AUTH_USER(state) {
    state.user = {
      id: null,
      email: null,
      isSpeaker: false,
    };
    state.permissions = [];
    state.permissionsAreInitialized = false;
  },
};

export const actions = {
  showAuthUser,
  resetAuthUser,
  showAuthUserPermissions,
};
