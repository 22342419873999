import apiClient from "@/api/modules/base";
import ROUTE from "./routes";

/**
 * Retrieve seminar documents
 *
 * @method
 * @memberOf seminarManagement
 * @param {object} params
 * @param {number} params.page
 * @param {number} params.perPage
 * @param {string} params.sortBy
 * @param {boolean} params.sortDesc
 * @param {boolean} params.seminarId
 * @returns {Promise<AxiosResponse<any>>}
 */
export const listSeminarDocuments = (params) =>
  apiClient.get(ROUTE.SEMINAR_DOCUMENTS.SEMINAR.LIST, { params });
