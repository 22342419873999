const UserManagement = () => import("@views/Restricted/UserManagement");
import users from "./users";
import roles from "./roles";
import userDeleteRequests from "./userDeleteRequests";
import userInvitations from "./userInvitations";

export default [
  {
    path: "/user-management",
    component: UserManagement,
    name: "Management",
    redirect: "/user-management/user",
    meta: {
      permissionNames: ["user.read"],
      label: "NAVIGATION.SIDE_NAVIGATION.USER_MANAGEMENT.PARENT",
      menuNames: ["sideNavigation"],
      icon: "far fa-cogs",
      order: -50,
    },
    children: [...users, ...roles, ...userDeleteRequests, ...userInvitations],
  },
];
