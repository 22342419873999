/**
 * @module vuex-navigation
 */

/**
 * Switch transitionState
 *
 * @param {object} vuex
 * @param {boolean} transitionState
 * @returns {Promise}
 */
const switchTransitionState = ({ commit }, transitionState) => {
  if (transitionState) {
    commit("SWITCH_TRANSITION_STATE", transitionState);
  } else {
    const delaySwitch = setTimeout(async () => {
      await commit("SWITCH_TRANSITION_STATE", transitionState);
      clearTimeout(delaySwitch);
    }, 200);
  }
};

export default switchTransitionState;
