<template>
  <v-checkbox
    v-model="checkboxValue"
    :label="fieldLabel"
    :error-messages="errorMessages"
    :rules="validation"
    :hide-details="!hasErrorMessages"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <template #label v-if="slotNotEmpty">
      <div class="checkboxLabel">
        <slot name="label" />
        <span>{{ required ? "*" : null }}</span>
      </div>
    </template>
  </v-checkbox>
</template>

<script>
import formValidation from "@/mixins/formValidation";

export default {
  name: "BaseCheckbox",

  mixins: [formValidation],

  data() {
    return {
      checkboxValue: this.value,
    };
  },

  props: {
    value: {
      type: undefined,
      required: true,
      default: null,
    },

    label: {
      type: String,
      required: false,
    },

    required: {
      type: Boolean,
      default: false,
      required: false,
    },

    rules: {
      type: Array,
      required: false,
    },

    errorMessages: {
      type: Array,
      default: null,
    },
  },

  computed: {
    validation() {
      if (this.rules) return this.rules;
      if (this.required) return this.requiredRules;

      return undefined;
    },

    fieldLabel() {
      if (this.required) return `${this.$t(this.label)} *`;

      return this.$t(this.label);
    },

    slotNotEmpty() {
      return !!this.$slots.label;
    },

    hasErrorMessages() {
      return !!this.errorMessages;
    },
  },

  watch: {
    value(value) {
      this.checkboxValue = value;
    },

    checkboxValue(value) {
      this.$emit("input", value);
    },
  },
};
</script>

<style lang="scss">
.v-input--checkbox {
  .v-input__slot {
    align-items: flex-start;
  }
}
</style>
