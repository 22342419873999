<template>
  <v-app>
    <v-app-bar light app color="white" height="100" absolute>
      <HeaderContent />
    </v-app-bar>

    <v-row no-gutters>
      <v-col cols="auto">
        <Sidenav />
      </v-col>

      <v-col>
        <v-main>
          <v-container class="py-2 px-3 py-md-14 px-md-16" fluid>
            <router-view />
          </v-container>
        </v-main>
      </v-col>
    </v-row>
  </v-app>
</template>

<script>
import HeaderContent from "@components/Layout/NavBar/index.vue";
import Sidenav from "@components/Sidenav/SideNav.vue";

export default {
  name: "MainLayout",
  components: { Sidenav, HeaderContent },
};
</script>
