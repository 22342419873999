<template>
  <div class="body-1">
    <AccountMenuItem v-for="(item, index) of items" :menuItem="item" :key="index" />
  </div>
</template>

<script>
import AccountMenuItem from "./AccountMenuItem";
import useMenuItems from "@/use/useMenuItems";
import store from "@store";
import router from "@router";

export default {
  name: "AccountMenuContent",

  setup() {
    const { items } = useMenuItems({
      menuName: "metaNavigation",
    });

    items.value.push({
      meta: {
        label: "NAVIGATION.META_NAVIGATION.LOGOUT",
      },
      command: async () => {
        await store.dispatch("auth/logoutUser");
        await router.push({ name: "Login" });
      },
    });

    return { items };
  },

  components: {
    AccountMenuItem,
  },
};
</script>
