export default {
  ROLES: {
    LIST: "/management/roles",
    SHOW: "/management/roles/show",
    CREATE: "/management/roles",
    DELETE: "/management/roles",
    UPDATE: "/management/roles",
    SYNC: {
      PERMISSIONS: "/management/roles/permission/sync",
      USERS: "/management/roles/user/sync",
    },
    ASSIGN: {
      PERMISSIONS: "/management/roles/add/permissions",
    },
    UNASSIGN: {
      PERMISSIONS: "/management/roles/remove/permissions",
    },
  },
};
