import { toNumber } from "lodash";

/**
 * Seperate string types into string, boolean and integer
 *
 * @param {string} value
 * @returns {string|boolean|number}
 * @author Dominik Niemann <dominik@polargold.de>
 */
const typeCastHelper = (value) => {
  const isNumber = new RegExp(/[+-]?([0-9]*[.])?[0-9]+/m);
  const hasLetters = new RegExp(/(([A-Z].*[0-9])|([0-9].*[A-Z]))/m);

  if (value === "true") {
    return true;
  }

  if (value === "false") {
    return false;
  }

  if (isNumber.test(value) && !hasLetters.test(value)) {
    return toNumber(value);
  }

  return value;
};

export default typeCastHelper;
