import apiClient from "@/api/modules/base";
import ROUTE from "./routes";

/**
 * Get Permissions
 *
 * @method
 * @memberOf userManagement
 * @param {object} params
 * @param {number} params.page
 * @param {number} params.perPage
 * @param {string} params.sortBy
 * @param {boolean} params.sortDesc
 * @returns {Promise<AxiosResponse<any>>}
 * @example myBWSapi.listPermissions(
 * { page: 1, perPage: 10, sortBy: 'columnName', sortDesc: false }
 * )
 * .then((response) => console.log(response));
 * @author Mirco Schuler <mirco@polargold.de>
 */
export const listPermissions = (params) => apiClient.get(ROUTE.PERMISSIONS.LIST, { params });
