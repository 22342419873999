<template>
  <v-combobox
    :label="fieldLabel"
    :filled="filled"
    :dense="dense"
    v-bind="$attrs"
    v-on="$listeners"
    :rules="validation"
  >
    <template v-for="name in $scopedSlots" #[name]="data">
      <slot :name="name" v-bind="data" />
    </template>
  </v-combobox>
</template>

<script>
import formValidation from "@/mixins/formValidation";

export default {
  name: "BaseCombobox",

  mixins: [formValidation],

  props: {
    label: {
      type: String,
      required: false,
      default: null,
    },

    filled: {
      type: Boolean,
      required: false,
      default: true,
    },

    dense: {
      type: Boolean,
      required: false,
      default: false,
    },

    required: {
      type: Boolean,
      default: false,
      required: false,
    },

    rules: {
      type: Array,
      required: false,
    },
  },

  computed: {
    validation() {
      if (this.rules) return this.rules;
      if (this.required) return this.requiredRules;

      return undefined;
    },

    fieldLabel() {
      if (this.required) return `${this.$t(this.label)} *`;

      return this.$t(this.label);
    },
  },
};
</script>
