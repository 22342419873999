<template>
  <BaseHover v-model="hover">
    <a
      v-bind="$attrs"
      :class="hover ? `${hoverColor}--text text-decoration-underline` : `${color}--text`"
      :href="link"
    >
      <slot />
    </a>
  </BaseHover>
</template>

<script>
export default {
  name: "BasePhoneLink",

  data() {
    return {
      hover: false,
    };
  },

  props: {
    color: {
      type: String,
      required: false,
      default: "gray600",
    },

    hoverColor: {
      type: String,
      required: false,
      default: "lowEmphasis",
    },

    phoneNumber: {
      type: String,
      required: true,
    },
  },

  computed: {
    link() {
      return `tel:${this.phoneNumber}`;
    },
  },
};
</script>
<style lang="scss" scoped>
.v-application a {
  color: inherit;
}
</style>
