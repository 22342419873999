import Vue from "vue";
import VueRouter from "vue-router";
import PublicRoutes from "@/router/Public";
import RestrictedRoutes from "@router/Restricted";

Vue.use(VueRouter);

const routes = [...PublicRoutes, ...RestrictedRoutes];

const router = new VueRouter({
  routes,
  mode: "hash",
});

export default router;
