<template>
  <v-row no-gutters>
    <v-col cols="12">
      <BaseHeadline size="4">{{ $t("BASE.DATE_RANGE_PICKER_FROM") }}</BaseHeadline>
      <BaseDateTimePicker
        v-model="value[0]"
        :max="fromMaxDate"
        :min="fromMinDate"
        :required="required"
        :errorMessagesFrom="errorMessagesFrom"
      />
    </v-col>

    <v-col cols="12">
      <BaseHeadline size="4">{{ $t("BASE.DATE_RANGE_PICKER_TO") }}</BaseHeadline>
      <BaseDateTimePicker
        v-model="value[1]"
        :min="toMinDate"
        :max="toMaxDate"
        :required="required"
        :errorMessagesTo="errorMessagesTo"
      />
    </v-col>
  </v-row>
</template>

<script>
import formValidation from "@/mixins/formValidation";

export default {
  name: "BaseDateRangePicker",

  mixins: [formValidation],

  data() {
    return {
      fromMinDate: null,
      fromMaxDate: null,
      toMinDate: null,
      toMaxDate: null,
      fromDate: null,
      toDate: null,
    };
  },

  props: {
    value: {
      type: Array,
      required: true,
      default: () => [],
    },

    minRange: {
      type: Date,
      required: false,
      default: null,
    },

    maxRange: {
      type: Date,
      required: false,
      default: null,
    },

    required: {
      type: Boolean,
      default: false,
      required: false,
    },

    errorMessagesFrom: {
      type: Array,
      default: null,
    },

    errorMessagesTo: {
      type: Array,
      default: null,
    },
  },

  watch: {
    value(value) {
      const [fromDate, toDate] = value;
      this.fromDate = fromDate;
      this.toDate = toDate;
    },

    fromDate(value) {
      if (this.carbon(value) > this.carbon(this.toDate)) {
        this.$emit("input", [value, value]);
      }
      if (this.carbon(value) !== this.carbon(this.toDate)) {
        this.toMinDate = new Date(this.carbon(value) + 86400000);
      }
    },

    toDate(value) {
      if (this.carbon(value) < this.carbon(this.fromDate)) {
        this.$emit("input", [value, value]);
      }
      if (this.carbon(value) !== this.carbon(this.fromDate)) {
        this.fromMaxDate = new Date(this.carbon(value) - 86400000);
      }
    },
  },

  methods: {
    carbon(date) {
      return date.getTime();
    },
  },

  mounted() {
    const [fromDate, toDate] = this.value;
    this.fromDate = fromDate;
    this.toDate = toDate;
    this.fromMaxDate = this.maxRange;
    this.toMinDate = this.minRange;
    this.fromMinDate = this.minRange;
    this.toMaxDate = this.maxRange;
  },
};
</script>
