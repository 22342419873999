/**
 * @module vuex-authUser
 */

/**
 * Log out user
 *
 * @param {object} vuex
 * @returns {Promise}
 */
const logoutUser = async ({ dispatch }) => {
  dispatch("reset/resetData", null, { root: true });
};

export default logoutUser;
