<template>
  <BaseTextButton iconLeft @click="goToDestination" v-on="$listeners">
    <slot v-if="hasSlot" />

    <span v-else>
      {{ $t("BASE.BACK_BUTTON.LABEL") }}
    </span>
  </BaseTextButton>
</template>

<script>
export default {
  name: "BaseBackButton",

  props: {
    route: {
      type: [String, Object],
      required: false,
      default: null,
    },
  },

  methods: {
    goBack() {
      this.$router.go(-1);
    },

    async goToRoute() {
      try {
        await this.$router.push(this.route);
      } catch {
        return;
      }
    },

    async goToDestination() {
      if (!this.route) {
        this.goBack();
      } else {
        await this.goToRoute();
      }
    },
  },

  computed: {
    hasSlot() {
      return !!this.$slots.default;
    },
  },
};
</script>
