import * as setMetaData from "./actions/setMetaData";
import * as setOpenGraphData from "./actions/setOpenGraphData";

export const namespaced = true;

export const state = {
  metaData: {
    title: "",
    description: "",
    keywords: "",
    canonicalURL: "",
  },

  openGraphData: {
    title: "",
    description: "",
  },
};

export const getters = {
  metaData: (state) => state.metaData,
};

export const mutations = {
  SET_META_DATA(state, metaData) {
    state.metaData = metaData;
  },

  SET_OPEN_GRAPH_DATA(state, openGraphData) {
    state.openGraphData = openGraphData;
  },
};

export const actions = {
  ...setMetaData,
  ...setOpenGraphData,
};
