import apiClient from "@/api/modules/base";
import ROUTE from "./routes";

/**
 * Get Roles
 *
 * @method
 * @memberOf userManagement
 * @param {object} params
 * @param {number} params.page
 * @param {number} params.perPage
 * @param {string} params.sortBy
 * @param {boolean} params.sortDesc
 * @returns {Promise<AxiosResponse<any>>}
 * @example myBWSapi.listRoles({ page: 1, perPage: 10, sortBy: 'columnName', sortDesc: false })
 * .then((response) => console.log(response));
 * @author Mirco Schuler <mirco@polargold.de>
 */
export const listRoles = (params) => apiClient.get(ROUTE.ROLES.LIST, { params });

/**
 * Show a Role
 *
 * @method
 * @memberOf userManagement
 * @param {object} params
 * @param {number} params.roleId
 * @returns {Promise<AxiosResponse<any>>}
 * @example myBWSapi.getRole
 * ({ roleId: 1 })
 * .then((response) => console.log(response));
 * @author Mirco Schuler <mirco@polargold.de>
 */
export const showRole = ({ roleId }) =>
  apiClient.get(ROUTE.ROLES.SHOW, {
    params: {
      roleId,
    },
  });

/**
 * Create a Role
 *
 * @method
 * @memberOf userManagement
 * @param {object} params
 * @param {string} params.name
 * @returns {Promise<AxiosResponse<any>>}
 * @author Mirco Schuler <mirco@polargold.de>
 */
export const createRole = (params) => apiClient.post(ROUTE.ROLES.CREATE, params);

/**
 * Update a Role
 *
 * @method
 * @memberOf userManagement
 * @param {object} params
 * @param {string} params.roleId
 * @param {string} params.name
 * @returns {Promise<AxiosResponse<any>>}
 * @author Mirco Schuler <mirco@polargold.de>
 */
export const updateRole = (params) => apiClient.put(ROUTE.ROLES.UPDATE, params);

/**
 * Create a Role
 *
 * @method
 * @memberOf userManagement
 * @param {object} params
 * @param {number} params.roleId
 * @returns {Promise<AxiosResponse<any>>}
 * @example myBWSapi.deleteRole
 * ({ roleId: 1 })
 * .then((response) => console.log(response));
 * @author Mirco Schuler <mirco@polargold.de>
 */
export const deleteRole = (params) =>
  apiClient.delete(ROUTE.ROLES.DELETE, {
    data: params,
  });

/**
 * Sync role permissions
 *
 * @method
 * @memberOf userManagement
 * @param {object} params
 * @param {number} params.roleId
 * @param {Array<Number>} params.permissionIds
 * @returns {Promise<AxiosResponse<any>>}
 */
export const syncRolePermissions = ({ roleId, permissionIds }) =>
  apiClient.put(ROUTE.ROLES.SYNC.PERMISSIONS, { roleId, permissionIds });

/**
 * Sync role users
 *
 * @method
 * @memberOf userManagement
 * @param {object} params
 * @param {number} params.roleId
 * @param {Array<Number>} params.userIds
 * @returns {Promise<AxiosResponse<any>>}
 */
export const syncRoleUsers = ({ roleId, userIds }) =>
  apiClient.post(ROUTE.ROLES.SYNC.USERS, { roleId, userIds });
