import apiClient from "@/api/modules/base";
import ROUTE from "./routes";

/**
 * @namespace unitop
 */

/**
 * Retrieve import types
 *
 * @memberOf unitop
 * @method
 * @returns {AxiosPromise<array<{key: string, displayName: string}>>}
 */
export const listImportTypes = () => apiClient.get(ROUTE.IMPORT_TYPES.INDEX);

/**
 * Show import details
 *
 * @memberOf unitop
 * @method
 * @param {number} importId
 * @returns {Promise<AxiosResponse<any>>}
 */
export const showFailedImport = (importId) =>
  apiClient.get(ROUTE.FAILED_IMPORTS.SHOW, { params: { importId } });
