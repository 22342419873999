import notify from "@/store/modules/notifications/actions/notify";
import switchNotificationsMenu from "@/store/modules/notifications/actions/switchNotificationsMenu";
import showUnreadCount from "@store/modules/notifications/actions/showUnreadCount";
import triggerReload from "@store/modules/notifications/actions/triggerReload";

export const namespaced = true;

export const state = {
  lastTenUnreadNotifications: [],
  messages: [],
  notificationsMenu: false,
  unreadCount: 0,
  reloadTrigger: false,
};

export const getters = {
  notificationsMenu: (state) => state.notificationsMenu,
  notifications: (state, getters) => getters.hasNotifications && state.notifications,
  unreadCount: (state) => state.unreadCount,
};

export const mutations = {
  TOGGLE_RELOAD(state) {
    state.reloadTrigger = !state.reloadTrigger;
  },

  SET_UNREAD_COUNT(state, count) {
    state.unreadCount = count;
  },

  ADD_NOTIFICATION(state, notification) {
    state.messages.push(notification);
  },

  REMOVE_NOTIFICATION(state) {
    state.messages.shift();
  },

  SWITCH_MESSAGE_MENU(state, isVisible) {
    state.notificationsMenu = isVisible;
  },
};

export const actions = {
  notify,
  switchNotificationsMenu,
  showUnreadCount,
  triggerReload,
};
