const UserList = () => import("@views/Restricted/UserManagement/User/List");
const UserAssignRoles = () => import("@views/Restricted/UserManagement/User/Assign/Role");

export default [
  {
    path: "user",
    name: "ManagementUserList",
    component: UserList,
    meta: {
      permissionNames: ["user.read"],
      menuNames: ["sideNavigation"],
      label: "NAVIGATION.SIDE_NAVIGATION.USER_MANAGEMENT.USER",
    },
  },

  {
    path: "user/:userId/assign/roles",
    name: "ManagementUserAssignRoles",
    component: UserAssignRoles,
    meta: { permissionNames: ["user.update"] },
  },
];
