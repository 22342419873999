<template>
  <div class="swiper" ref="swiper-container">
    <div class="swiper-wrapper">
      <div v-for="(slide, index) of slides" :key="index" class="swiper-slide">
        <slot name="slide" :slide="slide" />
      </div>
    </div>

    <div class="swiper-pagination" ref="swiper-pagination"></div>

    <div class="swiper-button-prev" ref="swiper-button-prev"></div>
    <div class="swiper-button-next" ref="swiper-button-next"></div>
  </div>
</template>

<script>
import Swiper, { Navigation, Pagination } from "swiper";
import "swiper/swiper-bundle.min.css";

export default {
  name: "BaseSwiper",

  data() {
    return {
      isLoading: true,
      swiper: null,
    };
  },

  props: {
    slides: {
      type: Array,
      required: true,
      default: () => [],
    },

    direction: {
      type: String,
      required: false,
      default: "horizontal",
    },

    effect: {
      type: String,
      required: false,
      default: "slide",
    },

    initSlide: {
      type: Number,
      required: false,
      default: 0,
    },

    loop: {
      type: Boolean,
      required: false,
      default: false,
    },

    preloadImages: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  methods: {
    async initSwiper() {
      this.isLoading = true;

      const options = {
        modules: [Navigation, Pagination],
        autoHeight: true,

        direction: this.direction,
        effect: this.effect,
        initialSlide: this.initSlide,
        loop: this.loop,
        preloadImages: this.preloadImages,

        navigation: {
          nextEl: this.$refs["swiper-button-next"],
          prevEl: this.$refs["swiper-button-prev"],
        },

        pagination: {
          el: this.$refs["swiper-pagination"],
        },
      };

      try {
        this.$nextTick(() => {
          this.swiper = new Swiper(this.$refs["swiper-container"], options);
        });

        return Promise.resolve();
      } finally {
        this.isLoading = false;
      }
    },
  },

  async mounted() {
    await this.initSwiper();
  },
};
</script>
