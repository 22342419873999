const ManagementUserInvitationList = () =>
  import("@views/Restricted/UserManagement/UserInvitation/List");
const ManagementUserInvitationShow = () =>
  import("@views/Restricted/UserManagement/UserInvitation/Show");
const ManagementUserInvitationEdit = () =>
  import("@views/Restricted/UserManagement/UserInvitation/Edit");
const ManagementUserInvitationCreate = () =>
  import("@views/Restricted/UserManagement/UserInvitation/Create");

export default [
  {
    path: "user-invitation",
    name: "management-user-invitation-list",
    component: ManagementUserInvitationList,
    meta: {
      permissionNames: ["invitation.read"],
      menuNames: ["sideNavigation"],
      label: "NAVIGATION.SIDE_NAVIGATION.USER_MANAGEMENT.USER_INVITATIONS",
    },
  },
  {
    path: "user-invitation/show/:id",
    name: "management-user-invitation-show",
    component: ManagementUserInvitationShow,
    meta: { permissionNames: ["invitation.read"] },
  },
  {
    path: "user-invitation/edit/:id",
    name: "management-user-invitation-edit",
    component: ManagementUserInvitationEdit,
    meta: { permissionNames: ["invitation.update"] },
  },

  {
    path: "user-invitation/create",
    name: "management-user-invitation-create",
    component: ManagementUserInvitationCreate,
    meta: { permissionNames: ["invitation.create"] },
  },
];
