<template>
  <BaseText class="debug-notice">DEBUG MODE ENABLED: v{{ version }}</BaseText>
</template>

<script>
import appVersion from "@/data/appVersion";

export default {
  name: "BaseDebugNotice",

  computed: {
    version() {
      return appVersion;
    },
  },
};
</script>

<style lang="scss" scoped>
.debug-notice {
  position: fixed;
  bottom: 0;
  right: 0;
  width: 100%;
  text-align: right;
  padding: 4px 10px;
  background: pink;
  opacity: 0.7;
  font-family: Arial, Helvetica, sans-serif;
}
</style>
