<template>
  <v-pagination
    @input="$emit('changePage', $event)"
    total-visible="9"
    color="primary600"
    :circle="true"
    v-bind="$attrs"
    v-on="$listeners"
  />
</template>

<script>
export default {
  name: "BasePagination",
};
</script>
