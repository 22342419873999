const Dashboard = () => import("@views/Restricted/Dashboard");
const DashboardWidgets = () =>
  import("@views/Restricted/Dashboard/DashboardWidgets/DashboardWidgets.vue");

export default {
  path: "/dashboard",
  component: Dashboard,
  children: [
    {
      path: "",
      name: "Dashboard",
      component: DashboardWidgets,
      meta: {
        menuTypes: ["common"],
        label: "NAVIGATION.SIDE_NAVIGATION.DASHBOARD",
        icon: "far fa-tachometer-alt",
        menuNames: ["sideNavigation"],
        order: -100,
      },
    },
  ],
};
