import apiClient from "@/api/modules/base";
import ROUTE from "./routes";

/**
 *
 * Retrieve list with seminar type ids
 *
 * @method
 * @memberOf seminarManagement
 * @param {object} params
 * @param {number} params.page
 * @param {number} params.perPage
 * @param {string} params.sortBy
 * @param {boolean} params.sortDesc
 * @returns {Promise<AxiosResponse<any>>}
 */
export const listSeminarTypes = (params) => apiClient.get(ROUTE.SEMINAR_TYPES.LIST, { params });
