import APP_VERSION from "./appVersion";

export default {
  VUEX_STORE:
    process.env.NODE_ENV === "production"
      ? `vuexStoreMyBWS-${APP_VERSION}`
      : `vuexStoreMyBWSdev-${APP_VERSION}`,
  SEMINAR_SEARCH:
    process.env.NODE_ENV === "production"
      ? `seminarSearchStoreMyBWS-${APP_VERSION}`
      : `seminarSearchStoreMyBWSdev-${APP_VERSION}`,
};
