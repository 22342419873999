const UserProfile = () => import("@views/Restricted/UserProfile");
const UserProfileShow = () => import("@views/Restricted/UserProfile/Show");
const UserProfileUpdate = () => import("@views/Restricted/UserProfile/Update/");
const UserProfileChangePassword = () => import("@views/Restricted/UserProfile/ChangePassword");

export default {
  path: "/user-profile",
  name: "UserProfile",
  redirect: "user-profile/show",
  meta: {
    menuNames: ["sideNavigation", "metaNavigation"],
    label: "NAVIGATION.SIDE_NAVIGATION.USER_PROFILE.PARENT",
    icon: "far fa-user-edit",
    order: -90,
  },
  component: UserProfile,
  children: [
    {
      path: "show",
      name: "UserProfileShow",
      component: UserProfileShow,
      meta: {
        label: "NAVIGATION.SIDE_NAVIGATION.USER_PROFILE.SHOW",
        menuNames: ["sideNavigation", "metaNavigation"],
      },
    },
    {
      path: "edit",
      name: "UserProfileUpdate",
      component: UserProfileUpdate,
      meta: {
        label: "NAVIGATION.SIDE_NAVIGATION.USER_PROFILE.UPDATE",
        menuNames: ["sideNavigation"],
        separator: "after",
      },
    },
    {
      path: "change-password",
      name: "UserProfileChangePassword",
      component: UserProfileChangePassword,
      meta: {
        label: "NAVIGATION.SIDE_NAVIGATION.USER_PROFILE.CHANGE_PASSWORD",
        menuNames: ["sideNavigation"],
      },
    },
  ],
};
