export default {
  props: {
    link: {
      type: Object,
      required: false,
      default: () => ({
        to: null,
        url: null,
        command: null,
        target: "_self",
      }),
    },
  },
};
