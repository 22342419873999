<template>
  <div @click="retrieveDocumentData" class="clickable">
    <slot />
  </div>
</template>

<script>
import openFile from "@/helper/openFile.js";

export default {
  name: "BaseDocumentData",

  props: {
    documentId: {
      type: Number,
      required: true,
    },
    isDownload: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  data() {
    return {
      isLoading: false,
      data: null,
    };
  },

  methods: {
    openFile() {
      openFile({
        file: this.data.content,
        fileName: this.data.seminarDocumentMeta.fileName,
        mimeType: this.data.seminarDocumentMeta.mimeType,
        download: this.isDownload,
      });
    },

    async retrieveDocumentData() {
      this.isLoading = true;

      if (!this.data) {
        try {
          const { data } = await this.$axios.seminars.retrieveDocumentData({
            seminarDocumentDataId: this.documentId,
          });

          this.data = data.data;
          this.openFile();
        } catch (err) {
          console.error(err);
        } finally {
          this.isLoading = false;
        }
      } else {
        this.openFile();
      }
    },
  },
};
</script>
