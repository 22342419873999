export default {
  AUTH_USER: {
    SHOW: "/auth-user/show",
    PROFILE: {
      SHOW: "/auth-user/profile",
      UPDATE: "/auth-user/profile",
      DELETE_REQUEST: {
        SHOW: "/auth-user/delete-request/show",
        CREATE: "/auth-user/delete-request",
        DELETE: "/auth-user/delete-request",
      },
    },
    PERMISSIONS: {
      SHOW: "/auth-user/permissions",
    },
    CHANGE_PASSWORD: "/auth-user/password",
    CREATE_MFA_TOKEN: "/authentication-token/authorized",
    PERSON_CHANGE: {
      LIST: "/auth-user/person-change",
      CREATE: "/auth-user/person-change",
      SHOW: "/auth-user/person",
    },
  },
};
