import apiClient from "@/api/modules/base";
import ROUTE from "./routes";

/**
 *
 * Retrieve list with seminar speakers
 *
 * @method
 * @memberOf seminarManagement
 * @param {object} params
 * @param {number} params.page
 * @param {number} params.perPage
 * @param {string} params.sortBy
 * @param {boolean} params.sortDesc
 * @returns {Promise<AxiosResponse<any>>}
 */
export const listSeminarSpeakers = (params) =>
  apiClient.get(ROUTE.SEMINAR_SPEAKERS.LIST, { params });

/**
 * Show specified seminar speaker
 *
 * @method
 * @memberOf seminarManagement
 * @param {object} params
 * @param {number} params.seminarSpeakerId
 * @returns {Promise<AxiosResponse<any>>}
 */
export const showSeminarSpeaker = (params) =>
  apiClient.get(ROUTE.SEMINAR_SPEAKERS.SHOW, { params });

/**
 * Update specified seminar speaker
 *
 * @method
 * @memberOf seminarManagement
 * @param {object} params
 * @param {number} params.seminarSpeakerId
 * @param {string} params.vitaText
 * @param {array<number>} params.seminarFieldOfActionIds
 * @returns {Promise<AxiosResponse<any>>}
 */
export const updateSeminarSpeaker = (params) =>
  apiClient.put(ROUTE.SEMINAR_SPEAKERS.UPDATE, params);

/**
 *
 * Retrieve list with seminar speaker seminars
 *
 * @method
 * @memberOf seminarManagement
 * @param {object} params
 * @param {number} params.page
 * @param {number} params.perPage
 * @param {array} params.sortBy
 * @param {array} params.sortDesc
 * @param {boolean} params.sortByStatusChange
 * @returns {Promise<AxiosResponse<any>>}
 */
export const listSeminarSpeakerSeminars = (params) =>
  apiClient.get(ROUTE.SEMINAR_SPEAKERS.SEMINARS.LIST, { params });

/**
 * Retrieve a Seminar
 *
 * @method
 * @memberOf seminarManagement
 * @param {object} params
 * @param {number} params.seminarId
 * @returns {Promise<AxiosResponse<any>>}
 */
export const showSeminarSpeakerSeminar = (params) =>
  apiClient.get(ROUTE.SEMINAR_SPEAKERS.SEMINARS.SHOW, { params });

/**
 * Store image for seminar speaker
 *
 * @method
 * @memberOf seminarManagement
 * @param {object} params
 * @param {number} params.seminarSpeakerId
 * @param {Blob} params.image
 * @returns {Promise<AxiosResponse<any>>}
 */
export const storeSeminarSpeakerImage = ({ seminarSpeakerId, image }) => {
  const formData = new FormData();
  formData.append("modelId", seminarSpeakerId);
  formData.append("mainImage", image);

  return apiClient.post(ROUTE.IMAGES.SEMINAR_SPEAKER.STORE, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

/**
 * Delete specified speaker image
 *
 * @method
 * @memberOf seminarManagement
 * @param {object} params
 * @param {object} params.mainImageId
 * @returns {Promise<AxiosResponse<any>>}
 */
export const deleteSeminarSpeakerImage = ({ mainImageId }) =>
  apiClient.delete(ROUTE.IMAGES.SEMINAR_SPEAKER.DELETE, {
    data: { modelId: mainImageId },
  });
