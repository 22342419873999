<template>
  <v-row no-gutters justify="center">
    <v-col cols="auto">
      <v-card>
        <BaseAlert :type="message.type" dismissible>
          {{ messageString }}
        </BaseAlert>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "Message",

  props: {
    message: {
      type: Object,
      required: true,
      default: () => ({
        type: null,
        message: null,
      }),
    },
  },

  computed: {
    messageString() {
      const hasString = this.$te(this.message.message);

      return hasString ? this.$t(this.message.message) : this.message.message;
    },
  },
};
</script>
