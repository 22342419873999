export default {
  IMPORTS: {
    LIST: "/management/unitop/imports/failed",
    SHOW: "/management/unitop/imports/failed/show",
    DELETE: "/navision-api/management/navision-imports",
    DISPATCH: {
      SINGLE: "/navision-api/management/navision-imports/dispatch/single",
      MULTI: "/navision-api/management/navision-imports/dispatch/multi",
    },
  },
};
