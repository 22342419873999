import apiClient from "@/api/modules/base";
import ROUTE from "./routes";

/**
 *
 * Retrieve list of seminar equipment
 *
 * @method
 * @memberOf seminarManagement
 * @param {object} params
 * @param {number} params.page
 * @param {number} params.perPage
 * @param {string} params.sortBy
 * @param {boolean} params.sortDesc
 * @param {string} params.search
 * @param {number} params.seminarVenueTypeId
 * @param {string} params.type Available: venue / room
 * @returns {Promise<AxiosResponse<any>>}
 */
export const listSeminarEquipment = (params) =>
  apiClient.get(ROUTE.SEMINAR_EQUIPMENT.LIST, { params });

/**
 * Show specified seminar venue equipment
 *
 * @method
 * @memberOf seminarManagement
 * @param {object} params
 * @param {number} params.seminarEquipmentId
 * @returns {Promise<AxiosResponse<any>>}
 */
export const showSeminarEquipment = (params) =>
  apiClient.get(ROUTE.SEMINAR_EQUIPMENT.LIST, { params });
