<template>
  <v-row
    v-if="parentIsActive"
    class="px-3 py-2 rounded-sm"
    :class="{ gray100: hover }"
    @mouseover="hover = true"
    @mouseleave="hover = false"
    no-gutters
  >
    <v-col cols="3" lg="2"> &nbsp; </v-col>
    <v-col cols="7" xl="8" class="clickable" @click="goTo(item.path)">
      <BaseText
        body="2"
        :color="isActive ? 'primary' : 'lowEmphasis'"
        v-html="$t(item.meta.label)"
      />
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "NavItemSecond",

  data() {
    return {
      hover: false,
    };
  },

  props: {
    item: Object,

    parentIsActive: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  computed: {
    ...mapGetters("navigation", ["isTransitioning"]),

    isActive() {
      return (
        this.$route.fullPath === this.item.path ||
        this.$router.history.current.matched.some((route) => route.path === this.item.path)
      );
    },
  },

  methods: {
    async goTo(route) {
      try {
        await this.$router.push(route);
      } catch {
        return;
      }
    },
  },
};
</script>
