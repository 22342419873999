import requestStatus from "@/data/requestStatus";

/**
 * @module vuex-reset
 */

/**
 * Reset data from different stores
 *
 * @param {object} vuex
 * @returns {Promise}
 */
const resetData = ({ commit, dispatch }) => {
  commit("CHANGE_STATUS", requestStatus.loading);
  dispatch("authUser/resetAuthUser", null, { root: true });
  dispatch("auth/resetAuth", null, { root: true });
  commit("CHANGE_STATUS", requestStatus.ready);
};

export default resetData;
