<template>
  <v-navigation-drawer
    :mini-variant="!showSideNav"
    permanent
    :transitionend="switchTransitionState(false)"
    class="app-bar--height"
  >
    <v-row
      class="mt-4"
      :class="!showSideNav ? '' : 'pr-4'"
      :justify="!showSideNav ? 'center' : 'end'"
    >
      <v-col cols="auto">
        <v-btn small icon @click="switchSideNav">
          <v-icon>
            {{ !showSideNav ? "fas fa-arrow-circle-right" : "fas fa-arrow-circle-left" }}
          </v-icon>
        </v-btn>
      </v-col>
    </v-row>

    <div class="mt-6" v-if="items">
      <v-row v-for="(item, index) in items" :key="index">
        <v-col cols="12">
          <NavItemFirst :item="item" />
        </v-col>

        <v-col cols="12" v-if="showSubMenu(item)">
          <NavItemSecond
            :item="subItem"
            :parentIsActive="itemIsActive(item)"
            v-for="(subItem, subIndex) in item.children"
            :key="subIndex"
          />
        </v-col>
      </v-row>
    </div>
  </v-navigation-drawer>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import NavItemFirst from "@components/Sidenav/partials/NavItemFirst.vue";
import NavItemSecond from "@components/Sidenav/partials/NavItemSecond.vue";
import useMenuItems from "@use/useMenuItems";

export default {
  name: "Sidenav",

  components: {
    NavItemFirst,
    NavItemSecond,
  },

  setup() {
    const { items } = useMenuItems({
      menuName: "sideNavigation",
    });

    return { items };
  },

  computed: {
    ...mapGetters("navigation", ["showSideNav", "isTransitioning"]),
    ...mapGetters("authUser", ["permissionAreLoaded"]),
  },

  methods: {
    ...mapActions("navigation", ["switchSideNav", "switchTransitionState"]),

    itemIsActive(item) {
      return (
        this.$route.fullPath === item.path ||
        this.$router.history.current.matched.some((route) => route.path === item.path)
      );
    },

    showSubMenu(item) {
      const isActive = this.itemIsActive(item);
      if (!isActive) return false;
      if (this.isTransitioning) return false;
      if (!this.showSideNav) return false;

      return item.children.length > 0;
    },
  },
};
</script>

<style scoped>
.app-bar--height {
  padding-top: 100px;
}
</style>
