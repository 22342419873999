<template>
  <span v-if="sumCosts">{{ $t("BASE.EXTRA_CHARGES.PRICE", { price: sumCosts }) }}</span>
</template>

<script>
import currency from "@helper/currencyHelper";
import feeTypes from "@data/feeTypes";
import { find, sum } from "lodash";

export default {
  name: "BaseExtraCharges",

  props: {
    participant: {
      type: Object,
      required: true,
    },
  },

  computed: {
    cartFees() {
      return this.participant.cartFees;
    },

    accommodationCosts() {
      const accommodationCosts = find(this.cartFees, { cartFeeTypeId: feeTypes.ACCOMMODATION_FEE });

      return accommodationCosts ? accommodationCosts.netPrice : 0;
    },

    cateringCosts() {
      const cateringCosts = find(this.cartFees, { cartFeeTypeId: feeTypes.CATERING_FEE });

      return cateringCosts ? cateringCosts.netPrice : 0;
    },

    sumCosts() {
      return currency(sum([this.accommodationCosts, this.cateringCosts]));
    },
  },
};
</script>
