<template>
  <span>
    {{ seminarPrice }}
  </span>
</template>

<script>
import currencyHelper from "@helper/currencyHelper";

export default {
  name: "BasePriceTag",

  props: {
    fee: {
      type: undefined,
      required: true,
      default: null,
    },

    hasFee: {
      type: Boolean,
      required: true,
      default: true,
    },
  },

  computed: {
    seminarPrice() {
      if (!this.hasFee) {
        return this.$t("BASE.PRICE_TAG.FREE");
      }

      if (this.hasFee && !this.fee) {
        return this.$t("BASE.PRICE_TAG.TBA");
      }

      return currencyHelper(this.fee);
    },
  },
};
</script>
