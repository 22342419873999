<template>
  <div class="base-divider my-8" :style="style"></div>
</template>

<script>
export default {
  name: "BaseDivider",

  computed: {
    style() {
      return {
        backgroundColor: this.$vuetify.theme.themes.light.black,
      };
    },
  },
};
</script>
<style lang="scss" scoped>
.base-divider {
  border: none;
  height: 2px;
  max-width: 100%;
}
</style>
