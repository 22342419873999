import errorMessages from "@/data/errorMessages";

/**
 *
 * Create initials from first and last name
 *
 * @param {string} firstName
 * @param {string} lastName
 * @returns {string}
 * @author Dominik Niemann <dominik@polargold.de>
 * @example initialsHelper('Erika', 'Musterfrau')
 *
 */
const initialsHelper = (firstName, lastName) => {
  if (!firstName || !lastName) throw new Error(errorMessages.INITIALS_HELPER.NO_VALUES);
  if (typeof firstName !== "string" || typeof lastName !== "string")
    throw new Error(errorMessages.INITIALS_HELPER.NO_STRING);

  return `${firstName[0].toUpperCase()}${lastName[0].toUpperCase()}`;
};

export default initialsHelper;
