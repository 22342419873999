/**
 * @module vuex-notifications
 */

/**
 * Trigger reload
 *
 * @param {object} vuex
 * @returns {Promise}
 */
const triggerReload = ({ commit }) => {
  commit("TOGGLE_RELOAD");
};

export default triggerReload;
