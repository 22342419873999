<template>
  <div>
    <v-dialog v-model="dialog" max-width="300" @click:outside="close">
      <v-card>
        <v-color-picker
          dot-size="28"
          hide-mode-switch
          mode="hexa"
          swatches-max-height="250"
          v-model="colorValue"
        />
        <v-card-actions>
          <v-row class="pb-3" v-if="!readOnly" no-gutters>
            <v-col cols="auto">
              <BaseButtonTertiary small @click="close">
                {{ $t("BASE.COLOR_PICKER_DIALOG.CANCEL") }}
              </BaseButtonTertiary>
            </v-col>
            <v-spacer />
            <v-col cols="auto">
              <BaseButtonTertiary small @click="confirm">
                {{ $t("BASE.COLOR_PICKER_DIALOG.CONFIRM") }}
              </BaseButtonTertiary>
            </v-col>
          </v-row>

          <v-row class="pb-3" v-if="readOnly" no-gutters>
            <v-spacer />
            <v-col cols="auto">
              <BaseButtonTertiary small @click="close">
                {{ $t("BASE.COLOR_PICKER_DIALOG.CLOSE") }}
              </BaseButtonTertiary>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <div
      :style="colorSwatch"
      class="elevation-2 clickable rounded-lg"
      @click="open"
      v-if="swatchTrigger"
    ></div>

    <div @click="open" v-if="!swatchTrigger">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: "BaseColorPickerDialog",

  data() {
    return {
      dialog: false,
      resetColor: null,
    };
  },

  props: {
    value: {
      type: String,
      required: true,
      default: "#FFFFFF",
    },

    swatchTrigger: {
      type: Boolean,
      required: false,
      default: true,
    },

    readOnly: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  methods: {
    close() {
      this.colorValue = this.resetColor;
      this.$emit("close");
      this.dialog = false;
    },

    confirm() {
      this.$emit("confirm");
      this.dialog = false;
    },

    open() {
      this.setResetColor();
      this.$emit("open");
      this.dialog = true;
    },

    setResetColor() {
      this.resetColor = this.value;
    },
  },

  computed: {
    colorValue: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },

    colorSwatch() {
      return {
        width: "32px",
        height: "32px",
        backgroundColor: this.colorValue,
      };
    },
  },
};
</script>
