import Vue from "vue";

import seminarManagement from "./modules/seminarManagement";
import userManagement from "./modules/userManagement";
import * as auth from "./modules/auth";
import * as notifications from "./modules/notifications";
import * as authUser from "./modules/authUser";
import * as countries from "./modules/countries";
import * as permissions from "./modules/permissions";
import * as roles from "./modules/roles";
import * as images from "./modules/images";
import * as navision from "./modules/navisionApi/imports";
import * as unitop from "./modules/unitop";
import * as seminarVenue from "./modules/seminarVenue";

const apiMethods = {
  auth,
  notifications,
  authUser,
  seminarManagement,
  userManagement,
  countries,
  permissions,
  roles,
  navision,
  images,
  unitop,
  seminarVenue,
};

Vue.prototype.$axios = apiMethods;
window.$axios = apiMethods;

export default apiMethods;
