<template>
  <v-icon v-bind="$attrs" v-on="$listeners">{{ iconName }}</v-icon>
</template>

<script>
import seminarTypes from "@data/seminarTypes";

export default {
  name: "BaseSeminarTypeIcon",

  props: {
    seminarType: {
      type: Object,
      required: true,
    },
  },

  computed: {
    iconName() {
      switch (this.seminarType.id) {
        case seminarTypes.WEB_SEMINAR:
          return "fal fa-desktop-alt";
        case seminarTypes.BLENDED_LEARNING:
          return "fal fa-blender";
        default:
          return "fal fa-user-friends";
      }
    },
  },
};
</script>
