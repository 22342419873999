<template>
  <v-app>
    <v-main class="d-flex justify-center align-center">
      <v-container>
        <v-row justify="center">
          <v-col cols="auto">
            <BaseLogoStampRed max-width="120px" class="heart" />
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: "MainLoader",

  props: {
    progress: {
      type: Number,
      required: false,
      default: 0,
    },
  },
};
</script>

<style lang="scss" scoped>
.heart {
  width: 120px;
  height: 120px;
  position: relative;
  transform: rotate(45deg);
  animation: animateHeart 1.2s infinite;

  &::before,
  &::after {
    content: "";
    width: 120px;
    height: 120px;

    position: absolute;
    border-radius: 50%;
  }

  &::before {
    left: -25px;
  }

  &::after {
    top: -25px;
  }
}

@keyframes animateHeart {
  0% {
    transform: rotate(45deg) scale(0.8);
  }
  5% {
    transform: rotate(45deg) scale(0.9);
  }
  10% {
    transform: rotate(45deg) scale(0.8);
  }
  15% {
    transform: rotate(45deg) scale(1);
  }
  50% {
    transform: rotate(45deg) scale(0.8);
  }
  100% {
    transform: rotate(45deg) scale(0.8);
  }
}
</style>
