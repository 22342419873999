<template>
  <h3 v-bind="$attrs" v-on="$listeners">
    <slot />
  </h3>
</template>

<script>
export default {
  name: "H3",
};
</script>
