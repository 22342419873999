import apiClient from "@/api/modules/base";
import ROUTE from "./routes";

/**
 * Retrieve user delete requests list
 *
 * @method
 * @memberOf userManagement
 * @param {object} params
 * @param {number} params.page
 * @param {number} params.perPage
 * @param {string} params.sortBy
 * @param {boolean} params.sortDesc
 * @returns {Promise<AxiosResponse<any>>}
 */
export const listUserDeleteRequests = (params) =>
  apiClient.get(ROUTE.USER_DELETE_REQUESTS.LIST, { params });

/**
 * Show user delete request
 *
 * @method
 * @memberOf userManagement
 * @param {object} params
 * @param {number} params.page
 * @param {number} params.perPage
 * @param {string} params.sortBy
 * @param {boolean} params.sortDesc
 * @returns {Promise<AxiosResponse<any>>}
 */
export const showUserDeleteRequest = ({ userDeleteRequestId }) =>
  apiClient.get(ROUTE.USER_DELETE_REQUESTS.SHOW, { params: { userDeleteRequestId } });

/**
 *  Delete user delete request
 *
 * @method
 * @memberOf userManagement
 * @param {object} params
 * @param {number} params.userDeleteRequestId
 * @returns {Promise<AxiosResponse<any>>}
 */
export const deleteUserDeleteRequest = ({ userDeleteRequestId }) =>
  apiClient.delete(ROUTE.USER_DELETE_REQUESTS.DELETE, { data: { userDeleteRequestId } });

/**
 *  Accept user delete request
 *
 * @method
 * @memberOf userManagement
 * @param {object} params
 * @param {number} params.userDeleteRequestId
 * @returns {Promise<AxiosResponse<any>>}
 */
export const acceptUserDeleteRequest = ({ userDeleteRequestId }) =>
  apiClient.put(ROUTE.USER_DELETE_REQUESTS.ACCEPT, { userDeleteRequestId });
