import Vue from "vue";
import Vuex from "vuex";
import cachingHelper from "@/helper/cachingHelper";
import storageHelper from "@/helper/storageHelper";
import storageNames from "@/data/storageNames";

import * as auth from "./modules/auth/index";
import * as navigation from "./modules/navigation/index";
import * as notifications from "./modules/notifications/index";
import * as reset from "./modules/reset/index";
import * as authUser from "./modules/authUser/index";
import * as seo from "./modules/seo";
import * as person from "./modules/person";
import * as initialize from "./modules/initialize";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    auth,
    navigation,
    notifications,
    reset,
    authUser,
    seo,
    person,
    initialize,
  },

  mutations: {
    INITIALIZE_STATE(state, cachedState) {
      Object.assign(state, cachedState);
    },
  },

  actions: {
    initializeState({ state, commit }) {
      const cachedState = storageHelper.read(storageNames.VUEX_STORE);
      if (cachedState) {
        commit("INITIALIZE_STATE", cachingHelper.read(state, cachedState));
      }
    },
  },
});
