import apiClient from "@/api/modules/base";
import ROUTE from "./routes";

/**
 * @namespace permissions
 */

/**
 *
 * @memberOf permissions
 * @method
 * @param {object} params
 * @param {number} params.perPage
 * @param {number} params.page
 * @param {Array<number>|null} params.sortBy
 * @param {Array<boolean>|null} params.sortDesc
 * @param {string|null} params.search
 * @returns {Promise<AxiosResponse<any>>}
 */
export const listPermissions = (params) => apiClient.get(ROUTE.PERMISSIONS.LIST, { params });
