import store from "@/store";
import { computed, onBeforeUnmount } from "vue";
import useRouteAuthorizationCheck from "@use/useRouteAuthorizationCheck";
import useTokenValidator from "@use/useTokenValidator";

export default () => {
  const { initializeRouteAuthenticationCheck } = useRouteAuthorizationCheck();
  const { initializeTokenValidator, removeTokenValidator } = useTokenValidator();

  const retrieveInitialData = async () => {
    const userIsAuthenticated = computed(() => store.getters["auth/isAuthenticated"]);
    if (!userIsAuthenticated.value) return;

    await Promise.all([
      store.dispatch("authUser/showAuthUser"),
      store.dispatch("authUser/showAuthUserPermissions"),
    ]);
  };

  const initialize = async () => {
    await initializeTokenValidator();
    await initializeRouteAuthenticationCheck();
  };

  onBeforeUnmount(() => {
    removeTokenValidator();
  });

  return { retrieveInitialData, initialize };
};
