export default {
  NOTIFICATIONS: {
    LIST: "/notifications",
    SHOW: "/notifications/show",
    RENDER: "/notifications/render",
    CREATE: "/management/notifications",
    DELETE: "/notifications",
    DELETE_ALL: "/notifications/delete/all",
    MARK_AS_READ_SINGLE: "/notifications/mark-as-read",
    MARK_AS_READ_MULTIPLE: "/notifications/mark-as-read/multi",
    MARK_AS_READ_ALL: "/notifications/mark-as-read/all",
    UNREAD_COUNT: "/notifications/unread/count",
  },
};
