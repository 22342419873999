import * as seminars from "@api/modules/seminarManagement/seminars";
import * as seminarSpeakerContracts from "@/api/modules/seminarManagement/seminarSpeakerContracts";
import * as seminarDocuments from "@api/modules/seminarManagement/seminarDocuments";
import * as seminarFieldOfActions from "@api/modules/seminarManagement/seminarFieldOfActions";
import * as seminarModules from "@api/modules/seminarManagement/seminarModules";
import * as seminarSpeakers from "@api/modules/seminarManagement/seminarSpeakers";
import * as seminarTopics from "@api/modules/seminarManagement/seminarTopics";
import * as seminarTypeMappings from "@api/modules/seminarManagement/seminarTypeMappings";
import * as seminarTypes from "@api/modules/seminarManagement/seminarTypes";
import * as seminarVenues from "@api/modules/seminarManagement/seminarVenues";
import * as seminarVenueTypes from "@api/modules/seminarManagement/seminarVenueTypes";
import * as seminarEquipment from "@api/modules/seminarManagement/seminarEquipment";

/**
 * @namespace seminarManagement
 */

export default {
  ...seminars,
  ...seminarSpeakerContracts,
  ...seminarDocuments,
  ...seminarFieldOfActions,
  ...seminarModules,
  ...seminarSpeakers,
  ...seminarTopics,
  ...seminarTypeMappings,
  ...seminarTypes,
  ...seminarVenues,
  ...seminarVenueTypes,
  ...seminarEquipment,
};
