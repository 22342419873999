export default {
  methods: {
    async goTo() {
      if (this.link.to) {
        try {
          await this.$router.push(this.link.to);
        } catch {
          return;
        }
      }

      if (this.link.url) {
        if (this.link.target === "_blank") {
          return window.open(this.link.url);
        }

        location.assign(this.link.url);
      }

      if (this.link.command) {
        this.link.command();
      }

      return;
    },
  },
};
