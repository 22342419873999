export default {
  SEMINAR_VENUES: {
    LIST: "/seminar-management/management/seminar-venues",
    UPDATE: "/seminar-management/management/seminar-venues",
    SHOW: "/seminar-management/management/seminar-venues/show",
    SYNC: {
      SEMINAR_EQUIPMENT: "/seminar-management/management/seminar-venues/sync/seminar-equipment",
      SEMINAR_VENUE_ROOM_EQUIPMENT:
        "/seminar-management/management/seminar-venues/sync/seminar-venue-room-equipment",
    },
    MAIN_IMAGE: {
      STORE: "/seminar-management/management/images/main-image/seminar-venue",
      DELETE: "/seminar-management/management/images/main-image/seminar-venue",
    },
    IMAGES: {
      LIST: "/seminar-management/management/seminar-venues/images",
      STORE: "/seminar-management/management/seminar-venues/images",
      DELETE: "/seminar-management/management/seminar-venues/images",
    },
  },
};
