import apiClient from "@/api/modules/base";
import ROUTE from "./routes";

const baseParams = {
  search: null,
  perPage: 10,
  page: 1,
  sortBy: null,
  sortDesc: null,
};

const payload = {
  ...baseParams,
  isSpeaker: null,
  hasUser: null,
};

/**
 * Retrieve list of people
 *
 * @method
 * @memberOf userManagement
 * @param params
 * @param {?string} params.search
 * @param {number} params.perPage=10
 * @param {number} params.page=1
 * @param {?string[]} params.sortBy
 * @param {?boolean[]} params.sortDesc
 * @param {?boolean} params.isSpeaker
 * @param {?boolean} params.hasUser
 * @returns {Promise<AxiosResponse<object[]>>}
 */
export const listPeople = (params = payload) => apiClient.get(ROUTE.SHOW, { params });
