<template>
  <v-switch v-bind="$attrs" v-on="$listeners" v-model="switchValue" inset>
    <template #label>
      <BaseText :color="switchValue ? 'primary1000' : null">
        {{ fieldLabel }}
      </BaseText>
    </template>
  </v-switch>
</template>

<script>
import formValidation from "@mixins/formValidation";

export default {
  name: "BaseSwitch",

  mixins: [formValidation],

  props: {
    value: {
      type: Boolean,
      required: true,
    },

    label: {
      type: String,
      required: false,
      default: null,
    },

    required: {
      type: Boolean,
      default: false,
      required: false,
    },

    errorMessages: {
      type: Array,
      default: null,
    },
  },

  computed: {
    validation() {
      if (this.rules) return this.rules;
      if (this.required) return this.requiredRules;

      return undefined;
    },

    fieldLabel() {
      if (this.required) return `${this.$t(this.label)} *`;

      return this.$t(this.label);
    },

    switchValue: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
};
</script>
