<template>
  <v-textarea
    :label="fieldLabel"
    filled
    v-model="textFieldValue"
    :rules="validation"
    v-bind="$attrs"
    v-on="$listeners"
    :error-messages="errorMessages"
  >
    <template #prepend-inner v-if="icon">
      <v-icon dense class="mr-2">{{ icon }}</v-icon>
    </template>
  </v-textarea>
</template>

<script>
import formValidation from "@/mixins/formValidation";

export default {
  name: "BaseTextArea",

  mixins: [formValidation],

  props: {
    value: {
      type: undefined,
      required: true,
      default: null,
    },

    label: {
      type: String,
      required: true,
      default: "",
    },

    rules: {
      type: Array,
      required: false,
    },

    icon: {
      type: String,
      required: false,
      default: "",
    },

    required: {
      type: Boolean,
      default: false,
      required: false,
    },

    errorMessages: {
      type: Array,
      default: null,
    },

    max: {
      type: String,
      required: false,
      default: "255",
    },
  },

  data() {
    return {
      textFieldValue: this.value,
    };
  },

  computed: {
    validation() {
      const rules = [];

      if (this.max !== "-1") {
        rules.push(...this.maxChars(this.max));
      }

      if (this.rules) {
        rules.push(...this.rules);
      }

      if (this.required) {
        rules.push(...this.requiredRules);
      }

      return rules;
    },

    fieldLabel() {
      if (this.required) return `${this.$t(this.label)} *`;

      return this.$t(this.label);
    },
  },

  watch: {
    value(value) {
      this.textFieldValue = value;
    },

    textFieldValue(value) {
      this.$emit("input", value);
    },
  },
};
</script>
