<template>
  <v-sheet :style="style" v-bind="$attrs" v-on="$listeners">
    <slot />
  </v-sheet>
</template>

<script>
export default {
  name: "BaseBorderSheet",

  props: {
    transparent: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  computed: {
    style() {
      return {
        border: `1px solid ${this.$vuetify.theme.themes.light.gray400}`,
        backgroundColor: this.transparent ? "transparent" : "white",
      };
    },
  },
};
</script>
