<template>
  <div class="badge badge__outer">
    <div v-if="active" class="badge badge__inner"></div>
  </div>
</template>

<script>
export default {
  name: "BaseBadge",

  props: {
    active: {
      type: Boolean,
    },
  },
};
</script>
<style lang="scss" scoped>
.badge {
  border-radius: 50%;
  display: inline-block;
  margin: 0 auto;

  &__outer {
    width: 18px;
    height: 18px;
    background: #e6e6e6;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    align-content: center;
  }

  &__inner {
    width: 10px;
    height: 10px;
    background: #0069b4;
  }
}
</style>
