import apiClient from "@/api/modules/base";
import ROUTE from "./routes";

/**
 * Retrieve list of seminar modules
 *
 * @method
 * @memberOf seminarManagement
 * @param {object} params
 * @param {number} params.seminarId
 * @param {number} params.page
 * @param {number} params.perPage
 * @param {string} params.sortBy
 * @param {boolean} params.sortDesc
 * @param {string} params.searc
 * @returns {Promise<AxiosResponse<any>>}
 */
export const listSeminarModules = (params) => apiClient.get(ROUTE.SEMINAR_MODULES.LIST, { params });

/**
 * Update specified seminar module
 *
 * @method
 * @memberOf seminarManagement
 * @param {object} params
 * @param {number} params.seminarModuleId
 * @returns {Promise<AxiosResponse<any>>}
 */
export const updateSeminarModule = (params) => apiClient.put(ROUTE.SEMINAR_MODULES.UPDATE, params);

/**
 * Show specified seminar module
 *
 * @method
 * @memberOf seminarManagement
 * @param {object} params
 * @param {number} params.seminarModuleId
 * @returns {Promise<AxiosResponse<any>>}
 */
export const showSeminarModule = ({ seminarModuleId }) =>
  apiClient.get(ROUTE.SEMINAR_MODULES.SHOW, { params: { seminarModuleId } });

/**
 * Delete specified seminar module
 *
 * @param {object} params
 * @param {number} params.seminarModuleId
 * @returns {Promise<AxiosResponse<any>>}
 */
export const deleteSeminarModule = ({ seminarModuleId }) =>
  apiClient.delete(ROUTE.SEMINAR_MODULES.DELETE, {
    data: { seminarModuleId },
  });

/**
 * Create new seminar module
 *
 * @param {object} params
 * @returns {Promise<AxiosResponse<any>>}
 */
export const createSeminarModule = (params) => apiClient.post(ROUTE.SEMINAR_MODULES.CREATE, params);
