import apiClient from "@/api/modules/base";
import ROUTE from "./routes";

/**
 * Retrieve seminar list
 *
 * @method
 * @memberOf seminarManagement.seminars
 * @param {object} params
 * @param {number} params.page
 * @param {number} params.perPage
 * @param {string} params.sortBy
 * @param {boolean} params.sortDesc
 * @param {string} params.search
 * @param {boolean} params.hasFee
 * @param {boolean} params.hasCatering
 * @param {boolean} params.hasAccommodation
 * @param {boolean} params.isPublic
 * @param {boolean} params.isPublished
 * @param {array<number>} params.seminarStatusIds
 * @returns {Promise<AxiosResponse<any>>}
 */
export const listSeminars = (params) => apiClient.get(ROUTE.SEMINARS.LIST, { params });

/**
 * Update specified seminar
 *
 * @method
 * @memberOf seminarManagement
 * @param {object} params
 * @param {number} params.seminarId
 * @returns {Promise<AxiosResponse<any>>}
 */
export const updateSeminar = (params) => apiClient.put(ROUTE.SEMINARS.UPDATE, { params });

/**
 * Retrieve seminar data to be edited
 *
 * @method
 * @memberOf seminarManagement
 * @param {object} params
 * @param {number} params.seminarId
 * @returns {Promise<AxiosResponse<any>>}
 */
export const showSeminar = ({ seminarId }) =>
  apiClient.get(ROUTE.SEMINARS.SHOW, { params: { seminarId } });
