import apiClient from "@/api/modules/base";
import ROUTE from "./routes";

/**
 *
 * Retrieve list with seminar venues types
 *
 * @method
 * @memberOf seminarManagement
 * @param {object} params
 * @param {number} params.page
 * @param {number} params.perPage
 * @param {string} params.sortBy
 * @param {boolean} params.sortDesc
 * @returns {Promise<AxiosResponse<any>>}
 */
export const listSeminarVenueTypes = (params) =>
  apiClient.get(ROUTE.SEMINAR_VENUE_TYPES.LIST, { params });

/**
 * Show specified seminar venue type
 *
 * @method
 * @memberOf seminarManagement
 * @param {object} params
 * @param {number} params.seminarVenueTypeId
 * @returns {Promise<AxiosResponse<any>>}
 */
export const showSeminarVenueType = (params) =>
  apiClient.get(ROUTE.SEMINAR_VENUE_TYPES.SHOW, { params });
