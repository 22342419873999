/**
 * @module vuex-authUser
 */

/**
 * Reset auth user
 *
 * @param {object} vuex
 * @returns {Promise}
 */
const resetAuthUser = ({ commit }) => {
  commit("RESET_AUTH_USER");
};

export default resetAuthUser;
