/**
 * @module vuex-notifications
 */

/**
 * Show count of unread notifications
 *
 * @param {object} vuex
 * @returns {Promise}
 */

const showUnreadCount = async ({ commit }) => {
  try {
    const { data } = await window.$axios.notifications.showUnreadCount();
    commit("SET_UNREAD_COUNT", data.data.notificationUnreadCount);

    return Promise.resolve();
  } catch (err) {
    return Promise.reject(err);
  }
};

export default showUnreadCount;
