import apiClient from "@/api/modules/base";
import ROUTE from "./routes";

/**
 * @namespace images
 */

/**
 * Retrieve image data
 *
 * @memberof images
 * @method
 * @param {object} params
 * @param {number} params.imageId=null
 * @returns {Promise<AxiosResponse<any>>}
 */
export const invoke = ({ imageId = null } = {}) =>
  apiClient.get(ROUTE.IMAGES.INVOKE, { params: { imageId } });
