export default {
  USERS: {
    LIST: "/management/users",
    SHOW: "/management/users/show",
    CREATE: "/management/users",
    DELETE: "/management/users",
    UPDATE: "/management/users",
    SYNC: {
      PERMISSIONS: "/management/users/sync/permissions",
      ROLES: "/management/users/role/sync",
      USER_GROUPS: "/management/users/sync/user-groups",
    },
    INVITATION: {
      SHOW: "/user-invitation",
      CREATE: "/user-invitation",
      SHOW_BY_TOKEN: "/user-invitation/getByInvitationToken",
    },
    ACTIVATION: "/management/users/activation",
  },
};
