import switchSideNav from "@/store/modules/navigation/actions/switchSideNav";
import switchTransitionState from "@/store/modules/navigation/actions/switchTransitionState";

export const namespaced = true;

export const state = {
  showSideNav: true,
  userMenu: false,
  isTransitioning: false,
};

export const getters = {
  showSideNav: (state) => state.showSideNav,
  isTransitioning: (state) => state.isTransitioning,
  userMenu: (state) => state.userMenu,
};

export const mutations = {
  SWITCH_SIDE_NAV(state) {
    state.showSideNav = !state.showSideNav;
  },

  SWITCH_TRANSITION_STATE(state, transitionState) {
    state.isTransitioning = transitionState;
  },
};

export const actions = {
  switchSideNav,
  switchTransitionState,
};
