/**
 * @module openFile
 */

/**
 * Decode blob data from laravel
 *
 * @method
 * @param {object} params
 * @param {?blob} params.file=null
 * @param {?string} params.encoding=null
 * @returns {Buffer}
 */
export const decodeFile = ({ file = null, encoding = null } = {}) => {
  if (!file) throw Error("Missing file data");
  if (!encoding) throw Error("Please specify encoding");

  return Buffer.from(file, encoding);
};

/**
 * Convert decoded blob to usable blob ;-)
 *
 * @method
 * @param {object} params
 * @param {?BinaryData} params.decodedFile=null
 * @param {?string} params.fileExtension=null
 *
 * @returns {blob}
 */
export const createBlob = ({ decodedFile = null, mimeType = null } = {}) => {
  if (!decodeFile) throw Error("Missing decoded file data");

  return new Blob([decodedFile], {
    type: mimeType,
  });
};

/**
 * Trigger file download
 *
 * @method
 * @param {object} params
 * @param {?objectUrl} params.objectUrl=null
 * @param {?string} params.fileName=null
 *
 * @returns {void}
 */
export const triggerDownload = ({ objectUrl = null, fileName = null } = {}) => {
  if (!objectUrl) throw Error("Missing objectUrl");
  if (!fileName) throw Error("Missing file name");

  const link = document.createElement("a");
  link.href = objectUrl;
  link.download = fileName;
  document.body.append(link);
  link.click();
  link.remove();
};

/**
 * Open a decoded file in new tab or as download
 *
 * @param {object} params
 * @param {?blob} params.file=null
 * @param {?string} params.fileName=null
 * @param {?string} params.fileExtension=null
 * @param {boolean} params.download=true Wether to download the file or open it in browser tab
 * @param {string} params.encoding="base64"
 *
 * @returns {void}
 */
const openFile = ({
  file = null,
  fileName = null,
  mimeType = null,
  download = true,
  encoding = "base64",
} = {}) => {
  if (!file) throw Error("Missing file data");
  if (!fileName) throw Error("Missing file name");

  const decodedFile = decodeFile({ file, encoding });
  const fileBlob = createBlob({ decodedFile, mimeType });
  const URI = URL.createObjectURL(fileBlob);

  if (!download) {
    window.open(URI, "_blank");
  } else {
    triggerDownload({ objectUrl: URI, fileName });
  }

  const timeout = setTimeout(() => {
    URL.revokeObjectURL(URI);
    clearTimeout(timeout);
  }, 7000);
};

export default openFile;
