<template>
  <v-text-field
    v-if="showMFAToken"
    :label="$t('BASE.TOKEN_GENERATOR.TEXT_FIELD_MFA_TOKEN_LABEL')"
    filled
    v-model="mfaToken"
    :rules="validationRules"
  >
    <template #prepend-inner>
      <v-icon dense class="mr-2">far fa-key</v-icon>
    </template>
    <template #append>
      <v-tooltip top>
        <template #activator="{ on }">
          <v-btn
            icon
            small
            :loading="loading || isLoading"
            :disabled="loading || isLoading"
            v-on="on"
            @click="requestMFAToken"
          >
            <v-icon small color="primary">fa fa-sync-alt</v-icon>
          </v-btn>
        </template>
        <span>{{ $t("BASE.TOKEN_GENERATOR.TEXT_FIELD_REFRESH_TOKEN_LABEL") }}</span>
      </v-tooltip>
    </template>
  </v-text-field>
</template>

<script>
import { mapActions } from "vuex";
import formValidation from "@mixins/formValidation";

export default {
  name: "BaseTokenGenerator",

  mixins: [formValidation],

  props: {
    value: {
      type: undefined,
      required: true,
      default: null,
    },

    username: {
      type: undefined,
      required: false,
      default: null,
    },

    password: {
      type: undefined,
      required: false,
      default: null,
    },

    validationRules: {
      type: Array,
      required: true,
      default: () => [],
    },

    showMFAToken: {
      type: Boolean,
      required: false,
      default: true,
    },

    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  data() {
    return {
      isLoading: false,
      mfaToken: this.value,
    };
  },

  watch: {
    value(value) {
      this.mfaToken = value;
    },
    mfaToken(value) {
      this.$emit("input", value);
    },
  },

  methods: {
    ...mapActions("notifications", ["notify"]),
    async requestMFAToken() {
      if (!this.username || !this.password) {
        return;
      }

      this.setLoading(true);

      try {
        await this.$axios.createToken({
          username: this.username,
          password: this.password,
        });
        this.notify({
          type: "info",
          message: "BASE.TOKEN_GENERATOR.MESSAGE_CREATED",
        });
        this.$emit("requestCreated");
      } catch (err) {
        console.error(err);
        this.setValidationErrors(err);
      } finally {
        this.setLoading(false);
      }
    },

    setLoading(value) {
      this.isLoading = value;
      this.$emit("loadingChanged", value);
    },
  },
};
</script>

<style lang="scss" scoped>
.buttonLabel {
  margin-top: 3px;
}
</style>
