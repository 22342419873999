/**
 * @module vuex-notifications
 */

/**
 * Create system wide notification
 *
 * @param {object} vuex
 * @param {object} notification
 * @param {string} notification.type
 * @param {string} notification.message
 * @returns {Promise}
 */
const notify = ({ commit }, notification) => {
  commit("ADD_NOTIFICATION", notification);
  setTimeout(() => {
    commit("REMOVE_NOTIFICATION");
  }, 8000);
};

export default notify;
