<template>
  <v-img :src="image" v-bind="$attrs" v-on="$listeners" />
</template>

<script>
import ImageUri from "@/helper/ImageUri";

export default {
  name: "Baseimage",

  data() {
    return {
      isLoading: true,
      image: null,
    };
  },

  props: {
    imageId: {
      type: Number,
      required: true,
    },
  },

  methods: {
    async invokeImage() {
      this.isLoading = true;

      try {
        const { data } = await this.$axios.images.invoke({ imageId: this.imageId });

        this.image = new ImageUri(data.imageData).create();
      } catch (err) {
        console.error(err);
      } finally {
        this.isLoading = false;
      }
    },
  },

  created() {
    this.invokeImage();
  },
};
</script>
