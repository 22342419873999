<template>
  <BaseHover v-model="hover">
    <v-row @click="$emit('click')" no-gutters class="clickable">
      <v-col cols="auto" align-self="center" v-if="iconLeft">
        <v-icon left small :color="hover ? colorHover : color" class="mr-6">
          {{ iconName ? `${iconName}` : "far fa-chevron-left" }}
        </v-icon>
      </v-col>

      <v-col cols="auto" align-self="center" style="padding-top: 3px">
        <BaseText body="2" :color="hover ? colorHover : color">
          <slot />
        </BaseText>
      </v-col>

      <v-col cols="auto" align-self="center" v-if="!iconLeft">
        <v-icon right small :color="hover ? colorHover : color" class="ml-6">
          {{ iconName ? `${iconName}` : "far fa-chevron-right" }}
        </v-icon>
      </v-col>
    </v-row>
  </BaseHover>
</template>

<script>
export default {
  name: "BaseTextButtonSecondary",

  data() {
    return {
      hover: false,
    };
  },

  props: {
    iconRight: Boolean,

    iconLeft: Boolean,

    iconName: {
      type: String,
      default: undefined,
    },

    color: {
      type: String,
      require: false,
      default: "lowEmphasis",
    },

    colorHover: {
      type: String,
      require: false,
      default: "primary",
    },
  },
};
</script>
