import i18n from "@/i18n";
import dayjs from "dayjs";

export const setMetaData = ({ commit }, metaData = {}) => {
  const { title, description, keywords, canonicalUrl } = metaData;

  /**
   * Create empty meta tags in html head element
   */
  const createMetaTags = () => {
    const hasDescription = document.getElementsByName("description").length > 0;
    const hasKeywords = document.getElementsByName("keywords").length > 0;

    const metaTags = [
      {
        name: "description",
        content: "",
      },
      {
        name: "keywords",
        content: "",
      },
    ];

    if (!hasDescription && !hasKeywords) {
      metaTags.forEach(({ name, content }) => {
        const meta = document.createElement("meta");
        meta.name = name;
        meta.content = content;
        document.getElementsByTagName("head")[0].appendChild(meta);
      });
    }
  };

  /**
   * Set canonical url
   *
   * @param {object} params
   * @param {string} params.canonicalURL
   * @returns void
   */
  const createCanonicalURL = ({ canonicalUrl = null } = {}) => {
    const canonicalHTMLTag = document.querySelectorAll("[rel=canonical]");
    const hasCanonical = canonicalHTMLTag.length > 0;
    const head = document.getElementsByTagName("head")[0];

    /**
     * Creates meta tag
     */
    const createTag = () => {
      const canonicalTag = document.createElement("link");

      canonicalTag.rel = "canonical";
      canonicalTag.href = canonicalUrl;

      head.appendChild(canonicalTag);
    };

    /**
     * Removes meta tag
     */
    const removeTag = () => {
      head.removeChild(canonicalHTMLTag[0]);
    };

    if (hasCanonical) removeTag();

    if (!canonicalUrl) return;

    createTag();
  };

  // Defaul values for meta tags

  const defaultMetaData = {
    title: i18n.t("SEO.DEFAULT_META_DATA.TITLE", { year: dayjs().format("YYYY") }),
    description: i18n.t("SEO.DEFAULT_META_DATA.DESCRIPTION"),
    keywords: i18n.t("SEO.DEFAULT_META_DATA.KEYWORDS"),
  };

  /**
   * Fill values into empty meta data tags
   *
   * @param {object} params
   * @param {string} params.title=defaultMetaData.title
   * @param {string} params.description=defaultMetaData.description
   * @param {string} params.keywords=defaultMetaData.keywords
   */
  const setDocumentMetaData = ({
    title = defaultMetaData.title,
    description = defaultMetaData.description,
    keywords = defaultMetaData.keywords,
  } = {}) => {
    document.title = title;
    document.getElementsByName("description")[0].setAttribute("content", description);
    document.getElementsByName("keywords")[0].setAttribute("content", keywords);
  };

  createMetaTags();
  createCanonicalURL({ canonicalUrl });
  setDocumentMetaData({ title, description, keywords });
  commit("SET_META_DATA", { title, description, keywords });
};
