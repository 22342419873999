import { isObject, isNull } from "lodash";

/**
 * Check if object values are null
 *
 * @param {object} sourceObject
 * @returns boolean
 * @author Dominik Niemann <dominik@polargold.de>
 */
const isNullifiedObjectHelper = (sourceObject) => {
  if (!isObject(sourceObject)) return false;

  const values = [];

  Object.values(sourceObject).forEach((value) => {
    if (isNull(value)) {
      values.push(true);
    } else {
      values.push(false);
    }
  });

  if (values.includes(false)) {
    return false;
  }

  return true;
};

export default isNullifiedObjectHelper;
