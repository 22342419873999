const Login = () => import("@/views/Public/Login");
const RequestAccount = () => import("@/views/Public/RequestAccount/RequestAccount.vue");
const RequestAccountForm = () =>
  import("@/views/Public/RequestAccount/partials/RequestAccountForm.vue");
const RequestAccountSuccess = () =>
  import("@/views/Public/RequestAccount/partials/RequestAccountSuccess.vue");
const RequestNewPassword = () =>
  import("@/views/Public/ResetPassword/RequestNewPassword/RequestNewPassword.vue");
const RequestNewPasswordForm = () =>
  import("@/views/Public/ResetPassword/RequestNewPassword/partials/RequestNewPasswordForm.vue");
const RequestNewPasswordSuccess = () =>
  import("@/views/Public/ResetPassword/RequestNewPassword/partials/RequestNewPasswordSuccess.vue");
const CreateNewPassword = () =>
  import("@/views/Public/ResetPassword/CreateNewPassword/CreateNewPassword.vue");
const ServerError = () => import("@/views/Public/ServerError");
const Maintenance = () => import("@/views/Public/Maintenance");
const PageNotFound = () => import("@/views/Public/PageNotFound");
const Unauthorized = () => import("@/views/Public/Unauthorized");
const UserInvitationFinish = () => import("@/views/Public/Register/index.vue");

export default [
  {
    path: "/login",
    name: "Login",
    component: Login,
    meta: { isPublic: true },
  },
  {
    path: "/request-account",
    component: RequestAccount,
    meta: { isPublic: true },
    children: [
      {
        path: "/",
        name: "RequestAccount",
        component: RequestAccountForm,
        meta: { isPublic: true },
      },
      {
        path: "success",
        name: "RequestAccountSuccess",
        component: RequestAccountSuccess,
        meta: { isPublic: true },
      },
    ],
  },
  {
    path: "/request-new-password",
    component: RequestNewPassword,
    meta: { isPublic: true },
    children: [
      {
        path: "/",
        name: "RequestNewPassword",
        component: RequestNewPasswordForm,
        meta: { isPublic: true },
      },
      {
        path: "success",
        name: "RequestNewPasswordSuccess",
        component: RequestNewPasswordSuccess,
        meta: { isPublic: true },
      },
    ],
  },

  {
    path: "/create-new-password",
    name: "CreateNewPassword",
    component: CreateNewPassword,
    meta: { isPublic: true },
  },

  {
    path: "/invitation",
    name: "UserInvitationFinish",
    component: UserInvitationFinish,
    meta: { isPublic: true },
  },

  {
    path: "/500",
    name: "500",
    component: ServerError,
    meta: { isPublic: true },
  },

  {
    path: "/503",
    name: "503",
    component: Maintenance,
    meta: { isPublic: true },
  },

  {
    path: "/401",
    name: "401",
    component: Unauthorized,
    meta: { isPublic: true },
  },

  {
    path: "*",
    name: "404",
    component: PageNotFound,
    meta: { isPublic: true },
  },
];
