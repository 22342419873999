<template>
  <h1 v-bind="$attrs" v-on="$listeners">
    <slot />
  </h1>
</template>

<script>
export default {
  name: "H1",
};
</script>
