import Cookies from "js-cookie";

const GA_ID = process.env.VUE_APP_GA_ID;

export const cookieService = {
  GA_ID,
  gaID() {
    return this.GA_ID.replace(/-/g, "_");
  },

  getCookieDomain() {
    const domainParts = window.location.hostname.split(".");
    const cookieDomains = [""];
    let cookieDomain = "";

    for (let i = domainParts.length - 1; i >= 0; i--) {
      cookieDomain = `.${domainParts[i]}${cookieDomain}`;
      cookieDomains.push(cookieDomain);
    }

    return cookieDomains;
  },

  isCookieAccepted() {
    return parseInt(Cookies.get("isCookieAccepted"), 10) === 1;
  },

  isCookieDeclined() {
    return parseInt(Cookies.get("isCookieAccepted"), 10) === 2;
  },

  acceptCookies() {
    Cookies.set("isCookieAccepted", 1, { expires: 31 });
  },

  declineCookies() {
    Cookies.set("isCookieAccepted", 2, { expires: 31 });
  },

  activateCookieRemoval() {
    document.addEventListener("declineCookies", () => {
      this.removeAllCookies();
    });
  },

  removeAllCookies() {
    this.getCookieDomain().forEach((domain) => {
      Cookies.remove("isCookieAccepted", { domain: domain });
      Cookies.remove("_ga", { domain: domain });
      Cookies.remove(`_gat_gtag_${this.gaID()}`, { domain: domain });
      Cookies.remove("_gid", { domain: domain });
    });

    location.reload();
  },
};
