import * as levels from "@/api/modules/userManagement/levels";
import * as permissions from "@/api/modules/userManagement/permissions";
import * as roles from "@/api/modules/userManagement/roles";
import * as seminarParticipants from "@/api/modules/userManagement/seminarParticipants";
import * as userDeleteRequests from "@/api/modules/userManagement/userDeleteRequests";
import * as userInvitations from "@/api/modules/userManagement/userInvitations";
import * as users from "@/api/modules/userManagement/users";
import * as people from "./people";

/**
 * @namespace userManagement
 */

export default {
  ...levels,
  ...permissions,
  ...roles,
  ...seminarParticipants,
  ...userDeleteRequests,
  ...userInvitations,
  ...users,
  ...people,
};
