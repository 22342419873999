/* eslint-disable no-shadow */
import requestStatus from "@/data/requestStatus";
import resetData from "@/store/modules/reset/actions/resetData";

export const namespaced = true;

export const state = {
  status: requestStatus.init,
};

export const mutations = {
  CHANGE_STATUS(state, status) {
    state.status = status;
  },
};

export const actions = {
  resetData,
};
