<template>
  <v-menu
    v-if="this.$vuetify.breakpoint.smAndDown"
    offset-y
    :nudge-bottom="50"
    left
    min-width="200"
    max-width="296"
    content-class="white px-5 py-5"
  >
    <template v-slot:activator="{ on, attrs }">
      <div v-bind="attrs" v-on="on">
        <v-icon>far fa-bars</v-icon>
      </div>
    </template>
    <v-col>
      <main-nav-content></main-nav-content>
    </v-col>
  </v-menu>

  <main-nav-content v-else></main-nav-content>
</template>
<script>
import MainNavContent from "@components/Layout/NavBar/partials/MainNav/partials/MainNavContent.vue";

export default {
  name: "main-nav",

  components: {
    MainNavContent,
  },
};
</script>
