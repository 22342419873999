<template>
  <v-text-field
    v-model="queue"
    @keydown="isEdited = true"
    @keyup="isEdited = true"
    v-bind="$attrs"
    v-on="$listeners"
    :label="$t('BASE.TABLE_SEARCH_FIELD.LABEL')"
    clearable
    filled
    dense
    background-color="gray200"
    @click:clear="triggerSearch"
    hide-details
  >
    <template #prepend-inner>
      <v-icon dense class="mr-2">far fa-search</v-icon>
    </template>
  </v-text-field>
</template>

<script>
export default {
  name: "BaseTableSearchField",

  props: {
    value: {
      type: undefined,
      required: true,
    },
  },

  data() {
    return {
      isEdited: false,
      editTimeOut: null,
      triggerTimeOut: null,
    };
  },

  computed: {
    queue: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },

  watch: {
    isEdited(value) {
      if (value) {
        this.editTimeOut = setTimeout(() => {
          this.isEdited = false;
          this.triggerSearch();
          clearTimeout(this.editTimeOut);
        }, 500);
      }
    },
  },

  methods: {
    triggerSearch() {
      this.triggerTimeOut = setTimeout(() => {
        if (!this.isEdited) {
          this.$emit("triggerSearch");
          clearTimeout(this.triggerTimeOut);
        }
      }, 500);
    },
  },
};
</script>
