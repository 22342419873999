<template>
  <v-combobox
    v-bind="$attrs"
    v-on="$listeners"
    v-model="filterValue"
    multiple
    clearable
    solo
    background-color="gray200"
    hide-details
    flat
    item-text="displayName"
    item-value="id"
    :label="labelField"
    :items="items"
  >
  </v-combobox>
</template>

<script>
export default {
  name: "BaseFilterCombobox",

  props: {
    value: {
      type: undefined,
      required: true,
    },

    label: {
      type: String,
      required: true,
    },

    items: {
      type: undefined,
      required: true,
    },
  },

  computed: {
    filterValue: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },

    labelField() {
      return this.$t(this.label);
    },
  },
};
</script>
