export const namespaced = true;

export const state = {
  isInitialized: false,
};

export const getters = {
  isInitialized: (state) => {
    return state.isInitialized;
  },
};

export const mutations = {
  SET_INITIALIZATION_STATE(state, initState) {
    state.isInitialized = initState;
  },
};

export const actions = {
  async changeInitializationState({ commit }, initState) {
    await commit("SET_INITIALIZATION_STATE", initState);
  },
};
