import apiClient from "@/api/modules/base";
import ROUTE from "./routes";

/**
 * Retrieve a list of users
 *
 * @method
 * @memberOf userManagement
 * @returns {Promise<AxiosResponse<any>>}
 * @author Dominik Niemann <dominik@polargold.de>
 * @example myBWSapi.getInactiveUsers('fsoejfgna98')
 * .then((response) => console.log(response));
 */
export const listUsers = (filterParams) =>
  apiClient.get(ROUTE.USERS.LIST, {
    params: filterParams,
  });

/**
 * Retrieve user profile data for editing
 *
 * @method
 * @memberOf userManagement
 * @param {object} payload
 * @param {number} payload.userId
 * @returns {Promise<AxiosResponse<any>>}
 */
export const showUser = ({ userId }) =>
  apiClient.get(ROUTE.USERS.SHOW, {
    params: {
      userId,
    },
  });

/**
 * Update profile data
 *
 * @method
 * @memberOf userManagement
 * @param {object} userProfile
 * @param {number} userProfile.userId
 * @param {string} userProfile.salutation
 * @param {string} userProfile.title
 * @param {string} userProfile.firstName
 * @param {string} userProfile.lastName
 * @param {string} userProfile.additionalAddressField
 * @param {string} userProfile.street
 * @param {string} userProfile.zipCode
 * @param {string} userProfile.city
 * @param {string} userProfile.country
 * @param {string} userProfile.phone
 * @param {number} userProfile.membershipTypeId
 * @param {string} userProfile.membershipNumber
 * @param {string} userProfile.companyName
 * @param {string} userProfile.companyMail
 * @param {string} userProfile.companyPhone
 * @param {array<number>} userProfile.roleIds
 * @returns {Promise<AxiosResponse<any>>}
 */
export const updateUserProfile = (userProfile) => apiClient.patch(ROUTE.USERS.UPDATE, userProfile);

/**
 * Delete specified user
 *
 * @method
 * @memberOf userManagement
 * @param {object} payload
 * @param {number} payload.userId
 * @returns {Promise<AxiosResponse<any>>}
 */
export const deleteUser = ({ userId }) =>
  apiClient.delete(ROUTE.USERS.DELETE, { data: { userId } });

/**
 * Sync user roles
 *
 * @method
 * @memberOf userManagement
 * @param {object} params
 * @param {number} params.userId
 * @param {Array<Number>} params.roleIds
 * @returns {Promise<AxiosResponse<any>>}
 */
export const syncUserRoles = ({ userId, roleIds }) =>
  apiClient.post(ROUTE.USERS.SYNC.ROLES, { userId, roleIds });

/**
 * Sync user permissions
 *
 * @method
 * @memberOf userManagement
 * @param {object} params
 * @param {number} params.userId
 * @param {Array<Number>} params.permissionIds
 * @returns {Promise<AxiosResponse<any>>}
 */
export const syncUserPermissions = ({ userId, permissionIds }) =>
  apiClient.put(ROUTE.USERS.SYNC.PERMISSIONS, { userId, permissionIds });

/**
 * Sync user user Groups
 *
 * @method
 * @memberOf userManagement
 * @param {object} params
 * @param {number} params.userId
 * @param {Array<Number>} params.userGroupIds
 * @returns {Promise<AxiosResponse<any>>}
 */
export const syncUserUserGroups = ({ userId, userGroupIds }) =>
  apiClient.put(ROUTE.USERS.SYNC.USER_GROUPS, { userId, userGroupIds });

/**
 * Activate or deactivate user
 *
 * @method
 * @memberOf userManagement
 * @param {object} params
 * @param {boolean} params.isActive
 * @param {number} params.userId
 * @returns {Promise<AxiosResponse<any>>}
 */
export const activateUser = ({ isActive = true, userId = null } = {}) =>
  apiClient.put(ROUTE.USERS.ACTIVATION, { isActive, userId });
