<template>
  <MainLayout v-if="isInitialized && userIsLoaded && permissionAreLoaded" />
</template>

<script>
import MainLayout from "@components/Layout/MainLayout.vue";
import { mapGetters } from "vuex";
import useAppInitialization from "@use/useAppInitialization";
import { onMounted, ref } from "vue";

export default {
  name: "ManagementHome",
  components: { MainLayout },

  computed: {
    ...mapGetters("initialize", ["isInitialized"]),
    ...mapGetters("authUser", ["userIsLoaded", "permissionAreLoaded"]),
  },

  setup() {
    const { retrieveInitialData } = useAppInitialization();
    const isLoading = ref(true);

    onMounted(async () => {
      await retrieveInitialData();
    });

    return { isLoading };
  },
};
</script>
