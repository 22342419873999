export default {
  zipCodeGermany: new RegExp(/^[0-9]{5}$/),
  email: new RegExp(/.+@.+\..+/),
  seminarNumber: new RegExp(/^BWS-(\d{3})-(\d{2})(\d{2})(\d{2})-(\d{2})$/),
  seminarNumberInURL: new RegExp(/BWS-(\d{3})-(\d{2})(\d{2})(\d{2})-(\d{2})$/),
  mfaToken: new RegExp(/^\d{6}$/u),
  passwordPolicy: new RegExp(/(?=.*?[^\w\s])(?=.*?[0-9])(?=.{8,})(?=.*?[A-Z]).*?[a-z].*/m),
  passwordLetters: new RegExp(/(?=.*?[A-Z]).*?[a-z].*/m),
  passwordNumbers: new RegExp(/(?=.*?[0-9])/m),
  passwordSymbols: new RegExp(/(?=.*?[^\w\s])/m),
  passwordLength: new RegExp(/(?=.{8,})/m),
  membershipNumber: new RegExp(/^[0-9]{7}$/),
  whiteSpace: new RegExp(/\s/g),
  baseComponent: new RegExp(/Base[A-Z]\w+\.(vue|js)$/),
  url: new RegExp(/^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w.-]+)+[\w\-._~:/?#[\]@!$&'()*+,;=.]+$/g),
  isoDate: new RegExp(/(\d{4}-\d{2}-\d{2})[A-Z]+(\d{2}:\d{2}:\d{2}).([0-9+-:]+)/),
};
