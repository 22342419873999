import VueGtag from "vue-gtag";
import { cookieService } from "@/helper/cookieService";

export default (vue) => {
  if (process.env.VUE_APP_GA_ID) {
    vue.use(VueGtag, {
      // eslint-disable-next-line camelcase
      config: { id: process.env.VUE_APP_GA_ID, params: { anonymize_ip: true } },
      enabled: cookieService.isCookieAccepted(),
    });
  }
};
