<template>
  <v-btn
    depressed
    :height="buttonHeight"
    v-bind="$attrs"
    v-on="$listeners"
    :color="color"
    :outlined="outlined"
  >
    <v-icon left v-if="iconLeft" small>
      {{ iconName ? `${iconName}` : "far fa-chevron-right" }}
    </v-icon>
    <span class="buttonLabel" :class="`${labelColor}--text`"><slot /></span>
    <v-icon right v-if="iconRight" small>
      {{ iconName ? `${iconName}` : "far fa-chevron-right" }}
    </v-icon>
  </v-btn>
</template>

<script>
export default {
  name: "BaseButton",
  props: {
    iconRight: Boolean,
    iconLeft: Boolean,

    iconName: {
      type: String,
      default: undefined,
    },

    small: {
      type: Boolean,
      default: false,
    },

    large: {
      type: Boolean,
      default: false,
    },

    color: {
      type: String,
      require: false,
      default: "primary",
    },

    labelColor: {
      type: String,
      require: false,
    },

    outlined: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    buttonHeight() {
      const sizes = ["48px", "58px"];

      if (this.small) return sizes[0];
      if (this.large) return sizes[1];

      return this.$vuetify.breakpoint.mdAndDown ? sizes[0] : sizes[1];
    },
  },
};
</script>
<style lang="scss" scoped>
.buttonLabel {
  margin-top: 3px;
}
</style>
