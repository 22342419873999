import apiClient from "@/api/modules/base";
import ROUTE from "./routes";

/**
 * @namespace auth
 */

/**
 * Login with user credentials
 *
 * @memberOf auth
 * @method
 * @param {object} payload
 * @param {string} payload.username
 * @param {string} payload.password
 * @param {string} payload.mfaToken
 * @returns {Promise<AxiosResponse<any>>}
 * @author Dominik Niemann <dominik@polargold.de>
 */
export const login = ({ username, password, mfaToken }) =>
  apiClient.post(ROUTE.AUTH.LOGIN, {
    username,
    password,
    token: mfaToken,
  });

/**
 * Validate token
 *
 * @memberOf auth
 * @method
 * @returns {Promise<AxiosResponse<{ authenticated: boolean, expiresAt: Date | string, expiresInSeconds: number }>>}
 * @author Dominik Niemann <dominik@polargold.de>
 */
export const validateToken = () => apiClient.get(ROUTE.AUTH.VALIDATE_TOKEN);

/**
 * Create MFA token with credentials
 *
 * @memberOf auth
 * @method
 * @param {object} params
 * @param {string} params.username
 * @param {string} params.password
 * @returns {Promise<AxiosResponse<any>>}
 */
export const createMFATokenGuest = ({ username, password }) =>
  apiClient.post(ROUTE.AUTH.MULTI_FACTOR.GUEST, { username, password });

/**
 * Create MFA token
 *
 * @memberOf auth
 * @method
 * @param {object} params
 * @returns {Promise<AxiosResponse<any>>}
 */
export const createMFATokenAuth = () => apiClient.post(ROUTE.AUTH.MULTI_FACTOR.AUTH);

/**
 * Request a new password
 *
 * @memberOf auth
 * @method
 * @param {string} username
 * @returns {Promise<AxiosResponse<any>>}
 *
 * @author Oliver Vollborn <oliver@polargold.de
 */
export const requestNewPassword = (username) =>
  apiClient.post(ROUTE.AUTH.REQUEST_NEW_PASSWORD, {
    username,
  });

/**
 * Create a new password with reset token
 *
 * @memberOf auth
 * @method
 * @param {object} params
 * @param {string} params.token
 * @param {string} params.password,
 * @param {string} params.passwordConfirmation,
 * @param {number} params.userId
 *
 * @returns {Promise<AxiosResponse<any>>}
 *
 * @author Oliver Vollborn <oliver@polargold.de
 */
export const createNewPassword = ({ token, password, passwordConfirmation, userId }) =>
  apiClient.post(ROUTE.AUTH.CREATE_NEW_PASSWORD, {
    token,
    password,
    userId,
    passwordConfirmation,
  });

/**
 * Show user data of person who requested password reset
 * @param {string} token
 * @returns {Promise<AxiosResponse<any>>}
 */
export const createNewPasswordShow = (token) =>
  apiClient.post(ROUTE.AUTH.CREATE_NEW_PASSWORD_SHOW, {
    token,
  });

/**
 * Request mybws account
 *
 * @memberOf auth
 * @method
 * @param {*} params
 * @param {string} params.salutation
 * @param {string} params.firstName
 * @param {string} params.lastName
 * @param {string} params.navisionNumber
 * @param {string} params.companyName
 * @param {string} params.companyAddress
 * @param {boolean} params.checkConditions
 * @param {boolean} params.checkDataProcessing
 * @param {boolean} params.checkPrivacyPolicy
 * @returns {Promise<AxiosResponse<any>>}
 */
export const requestAccount = (params) => apiClient.post(ROUTE.AUTH.REQUEST_ACCOUNT, params);

/**
 * Retrieve inviation data
 *
 * @memberOf auth
 * @method
 * @param {object} params
 * @param {number} params.token
 * @returns {Promise<AxiosResponse<any>>}
 */
export const showUserInvitation = ({ token }) =>
  apiClient.get(ROUTE.AUTH.USER_INVITATIONS.SHOW, { params: { token } });

/**
 * Register new user
 *
 * @memberOf auth
 * @method
 * @param {object} params
 * @param {object} params.invitationId
 * @param {object} params.password
 * @param {object} params.passwordConfirm
 * @param {object} params.token
 * @returns {Promise<AxiosResponse<any>>}
 */
export const registerUser = (params) => apiClient.post(ROUTE.AUTH.REGISTER, params);
