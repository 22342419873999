<template>
  <v-btn
    depressed
    :outlined="secondary"
    color="transparent"
    :disabled="disabled"
    :loading="loading"
    height="38px"
    v-bind="$attrs"
    v-on="$listeners"
    v-on:click.self="$emit('click')"
  >
    <v-icon left v-if="iconLeft" small color="primary">
      {{ iconName ? `${iconName}` : "far fa-chevron-left" }}
    </v-icon>
    <span class="buttonLabel primary--text"><slot /></span>
    <v-icon right v-if="iconRight" small color="primary">
      {{ iconName ? `${iconName}` : "far fa-chevron-right" }}
    </v-icon>
  </v-btn>
</template>

<script>
export default {
  name: "BaseTextButton",
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    iconRight: Boolean,
    iconLeft: Boolean,
    iconName: {
      type: String,
      default: undefined,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    secondary: {
      type: Boolean,
      default: false,
    },
    small: {
      type: Boolean,
      default: false,
    },
    large: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
<style lang="scss" scoped>
.buttonLabel {
  margin-top: 3px;
}
</style>
