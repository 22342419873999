<template>
  <v-alert
    border="left"
    text
    :type="type"
    :icon="iconName"
    min-width="300"
    :dismissible="dismissible"
  >
    <BaseText>
      <slot />
    </BaseText>

    <slot name="custom" />
  </v-alert>
</template>

<script>
export default {
  name: "BaseAlert",
  props: {
    type: {
      type: String,
      default: "info",
    },
    dismissible: Boolean,
  },
  computed: {
    iconName() {
      switch (this.type) {
        case "warning":
          return "fa-exclamation-circle";
        case "success":
          return "fa-check-circle";
        case "error":
          return "fa-exclamation-triangle";
        case "info":
          return "fa-info-circle";
        default:
          return false;
      }
    },
  },
};
</script>
