<template>
  <span class="rounded mr-1 d-inline-block" :class="[colorClass]" :style="style"></span>
</template>

<script>
export default {
  name: "BaseDot",

  props: {
    color: {
      type: undefined,
      required: false,
      default: null,
    },

    size: {
      type: String,
      required: false,
      default: "10",
    },

    hex: {
      type: String,
      required: false,
      default: "#0069b4",
    },
  },

  computed: {
    colorClass() {
      if (!this.color) return "primary";

      return this.color;
    },

    style() {
      return {
        backgroundColor: this.hex || "inherit",
        width: `${this.size}px`,
        height: `${this.size}px`,
      };
    },
  },
};
</script>
