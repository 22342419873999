<template>
  <v-avatar color="primary" v-bind="$attrs" v-on="$listeners" :size="size">
    <span
      class="white--text font-weight-bold"
      v-if="!!initials && !hasImage && !loading"
      :style="style"
    >
      {{ initials }}
    </span>
    <img :src="image" v-if="hasImage && !loading" />
    <v-progress-circular indeterminate color="white" v-if="loading" :width="3" />
  </v-avatar>
</template>

<script>
import initialsHelper from "@helper/initialsHelper";
export default {
  name: "BaseAvatar",

  props: {
    size: {
      type: String,
      required: false,
      default: "96",
    },

    firstName: {
      type: String,
      required: false,
    },

    lastName: {
      type: String,
      required: false,
    },

    image: {
      type: String,
      required: false,
    },

    displayName: {
      type: String,
      required: false,
    },

    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  computed: {
    style() {
      return {
        fontSize: `${this.size * 0.35}px`,
        marginTop: "7%",
      };
    },

    hasFullName() {
      return !!this.firstName && !!this.lastName;
    },

    hasSplitName() {
      return !!this.displayName;
    },

    hasImage() {
      return !!this.image;
    },

    displayNameInitials() {
      const splitName = this.displayName.split(" ");

      return initialsHelper(splitName[0], splitName[1]);
    },

    fullNameInitials() {
      return initialsHelper(this.firstName, this.lastName);
    },

    initials() {
      if (this.hasFullName) return this.fullNameInitials;
      if (this.hasSplitName) return this.displayNameInitials;

      return null;
    },
  },
};
</script>
