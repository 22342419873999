export const setOpenGraphData = ({ commit }, openGraphData = {}) => {
  const { title, description, imageURL } = openGraphData;

  const createMetaTags = () => {
    const hasTitle = document.getElementsByName("og:title").length > 0;
    const hasDescription = document.getElementsByName("og:description").length > 0;

    const metaTags = [
      {
        name: "og:title",
        content: "",
      },
      {
        name: "og:description",
        content: "",
      },
      {
        name: "og:image",
        content: "",
      },
    ];

    if (!hasDescription && !hasTitle) {
      metaTags.forEach(({ name, content }) => {
        const meta = document.createElement("meta");
        meta.name = name;
        meta.content = content;
        document.getElementsByTagName("head")[0].appendChild(meta);
      });
    }
  };

  const setDocumentMetaData = async ({ title, description }) => {
    document.getElementsByName("og:description")[0].setAttribute("content", description);
    document.getElementsByName("og:title")[0].setAttribute("content", title);
    document.getElementsByName("og:image")[0].setAttribute("content", imageURL);
  };

  createMetaTags();

  commit("SET_OPEN_GRAPH_DATA", { title, description });
  setDocumentMetaData({ title, description });
};
