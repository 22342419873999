<template>
  <v-container fluid class="pa-0">
    <v-row align="center" justify="space-between" no-gutters>
      <v-col cols="auto" md="3" lg="2" order="1" order-md="0">
        <BaseLogoMain class="clickable" @click="goHome" :maxWidth="logoSize" />
      </v-col>

      <v-col cols="auto" md="6" lg="8" order="0" order-md="1">
        <MainNav />
      </v-col>

      <v-col cols="auto" md="3" lg="2" order="2">
        <MetaNav />
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { mapState } from "vuex";
import MainNav from "@components/Layout/NavBar/partials/MainNav";
import MetaNav from "@components/Layout/NavBar/partials/MetaNav";

export default {
  name: "NavBar",

  components: {
    MainNav,
    MetaNav,
  },

  computed: {
    ...mapState(["navigation"]),

    smAndDown() {
      return this.$vuetify.breakpoint.smAndDown;
    },

    logoSize() {
      if (this.smAndDown) return "100px";

      return "160px";
    },
  },

  methods: {
    async goHome() {
      try {
        await this.$router.push({ name: "Dashboard" });
      } catch {
        return;
      }
    },
  },
};
</script>
