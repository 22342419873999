import apiClient from "@/api/modules/base";
import ROUTE from "./routes";

/**
 * Retrieve seminar list
 *
 * @method
 * @memberOf seminarManagement
 * @param {object} params
 * @param {number} params.page
 * @param {number} params.perPage
 * @param {string} params.sortBy
 * @param {boolean} params.sortDesc
 * @param {string} params.search
 * @returns {Promise<AxiosResponse<any>>}
 */
export const listSeminarFieldOfActions = (params) =>
  apiClient.get(ROUTE.SEMINAR_FIELD_OF_ACTIONS.LIST, { params });

/**
 * Update field of action
 *
 * @method
 * @memberOf seminarManagement
 * @param {object} params
 * @param {number} params.seminarFieldOfActionId
 * @param {string} params.color
 * @returns {Promise<AxiosResponse<any>>}
 */
export const updateSeminarFieldOfAction = (params) =>
  apiClient.put(ROUTE.SEMINAR_FIELD_OF_ACTIONS.UPDATE, params);
