import { upperFirst, camelCase } from "lodash";

export default (Vue) => {
  const requireComponent = require.context("../components/Base", false, /Base[A-Z]\w+\.(vue|js)$/);

  requireComponent.keys().forEach((fileName) => {
    const componentConfig = requireComponent(fileName);

    const componentName = upperFirst(camelCase(fileName.replace(/^\.\/(.*)\.\w+$/, "$1")));

    Vue.component(componentName, componentConfig.default || componentConfig);
  });
};
