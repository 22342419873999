<template>
  <BaseHover v-model="hover">
    <BaseLinkSwitch :link="link" v-bind="$attrs" v-on="$listeners" :class="classes">
      <slot />
    </BaseLinkSwitch>
  </BaseHover>
</template>

<script>
export default {
  name: "BaseLink",

  data() {
    return {
      hover: false,
    };
  },

  props: {
    color: {
      type: String,
      required: false,
      default: "gray600",
    },

    hoverColor: {
      type: String,
      required: false,
      default: "lowEmphasis",
    },

    link: {
      type: Object,
      required: false,
      default: () => ({
        to: null,
        url: null,
        command: null,
        target: null,
      }),
    },

    phone: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  computed: {
    linkObject() {
      return {
        ...this.link,
        url: this.phone ? `tel:${this.link.url}` : this.link.url,
      };
    },

    baseClasses() {
      return ["clickable"];
    },

    normalState() {
      return [`${this.color}--text`, "text-decoration-none"];
    },

    hoverState() {
      return [`${this.color}--text`, "text-decoration-underline"];
    },

    classes() {
      return [...this.baseClasses, this.hover && this.hoverState, !this.hover && this.normalState];
    },
  },
};
</script>

<style lang="scss" scoped>
.v-application a {
  color: inherit;
}
</style>
