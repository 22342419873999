/**
 * @module vuex-notifications
 */

/**
 * Toggle notification menu
 *
 * @param {object} vuex
 * @param {string} closeMenu
 * @returns {Promise}
 */
const switchNotificationsMenu = ({ commit, state }, closeMenu) => {
  if (closeMenu === "close") {
    commit("SWITCH_MESSAGE_MENU", false);
  } else {
    commit("SWITCH_MESSAGE_MENU", !state.notificationsMenu);
  }
};

export default switchNotificationsMenu;
