import axios from "axios";
import store from "@/store";
import router from "@/router";

const api = axios.create({
  baseURL: process.env.VUE_APP_BACKEND_URL,
  headers: {
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, PATCH, OPTIONS",
    "Access-Control-Allow-Headers": "X-Requested-With, content-type, Authorization",
  },
});

api.interceptors.request.use((req) => {
  const token = store.getters["auth/accessToken"];

  if (token) {
    req.headers.authorization = `Bearer ${token}`;
  }

  return req;
});

const goTo = async (name) => {
  try {
    await router.push({ name });
  } catch {
    return;
  }
};

const setError = (code) => {
  store.dispatch("auth/setErrorMode", { status: true, code });
};

const unsetError = () => {
  if (!store.getters["auth/hasError"]) return;
  store.dispatch("auth/setErrorMode", { status: false, code: null });
};

api.interceptors.response.use(
  (res) => {
    unsetError();

    return res;
  },
  (err) => {
    if (err.response) {
      const { response } = err;
      const { status } = response;
      if (response) {
        switch (status) {
          case 401:
            goTo("Login");
            break;

          case 500:
            setError(500);
            goTo("500");
            break;

          case 503:
            setError(503);
            goTo("503");
            break;

          default:
            return Promise.reject(err);
        }
      }
    }

    return Promise.reject(err);
  }
);

export default api;
