import store from "@/store";
import { isEmpty } from "lodash";

/**
 * Check if user has permissions
 *
 * @param {object} params
 * @param {array<string>} [params.permissionNames]="[]"
 * @param {array<string>} [params.permissionProps]="[]"
 * @param {boolean} [params.acceptOnePermission]=true Decide if one permission is enough to grant access or if user has to have all permissions to get access
 *
 * @returns {boolean}
 */
const hasPermission = ({
  permissionNames = [],
  permissionProps = [],
  acceptOnePermission = true,
} = {}) => {
  if (isEmpty(permissionProps) && isEmpty(permissionNames)) return true;

  const checkPermissionName = () => {
    const userPermissions = store.getters["authUser/userPermissions"];

    if (!userPermissions.length) return false;

    return userPermissions.some((permissionName) => permissionNames.includes(permissionName));
  };

  const checkPermissionProp = () => {
    const user = store.getters["authUser/user"];
    const userPermissionProps = [];

    if (!user) return false;

    permissionProps.forEach((permissionProp) => {
      if (permissionProp in user) {
        userPermissionProps.push(user[permissionProp]);
      }
    });

    return userPermissionProps.some((permissionProp) => !!permissionProp);
  };

  if (acceptOnePermission) {
    return checkPermissionProp() || checkPermissionName();
  } else {
    return checkPermissionProp() && checkPermissionName();
  }
};

export default hasPermission;
