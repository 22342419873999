import apiClient from "@/api/modules/base";
import ROUTE from "./routes";

/**
 *
 * Retrieve list with seminar venues
 *
 * @method
 * @memberOf seminarManagement
 * @param {object} params
 * @param {number} params.page
 * @param {number} params.perPage
 * @param {string} params.sortBy
 * @param {boolean} params.sortDesc
 * @returns {Promise<AxiosResponse<any>>}
 */
export const listSeminarVenues = (params) => apiClient.get(ROUTE.SEMINAR_VENUES.LIST, { params });

/**
 * Show specified seminar venue
 *
 * @method
 * @memberOf seminarManagement
 * @param {object} params
 * @param {number} params.seminarVenueId
 * @returns {Promise<AxiosResponse<any>>}
 */
export const showSeminarVenue = (params) => apiClient.get(ROUTE.SEMINAR_VENUES.SHOW, { params });

/**
 * Update specified seminar venue
 *
 * @method
 * @memberOf seminarManagement
 * @param {object} params
 * @param {number} params.seminarVenueId
 * @param {string} params.seminarVenueTypeId
 * @param {string} params.name2
 * @param {string} params.name3
 * @param {string} params.teaser
 * @param {string} params.description
 * @param {string} params.cateringInfo
 * @param {string} params.vrLink
 * @returns {Promise<AxiosResponse<any>>}
 */
export const updateSeminarVenue = (params) => apiClient.put(ROUTE.SEMINAR_VENUES.UPDATE, params);

/**
 * Assign seminar equipment to seminar
 *
 * @method
 * @memberOf seminarManagement
 * @param {object} params
 * @param {number} params.seminarVenueId
 * @param {array<number>} params.seminarEquipmentIds
 * @returns {Promise<AxiosResponse<any>>}
 */
export const syncSeminarEquipment = (params) =>
  apiClient.put(ROUTE.SEMINAR_VENUES.SYNC.SEMINAR_EQUIPMENT, params);

/**
 * Assign seminar venue room equipment to seminar venue
 *
 * @method
 * @memberOf seminarManagement
 * @param {object} params
 * @param {number} params.seminarVenueId
 * @param {array<number>} params.seminarEquipmentIds
 * @returns {Promise<AxiosResponse<any>>}
 */
export const syncSeminarVenueRoomEquipment = (params) =>
  apiClient.put(ROUTE.SEMINAR_VENUES.SYNC.SEMINAR_VENUE_ROOM_EQUIPMENT, params);

/**
 * Store main image for seminar venue
 *
 * @method
 * @memberOf seminarManagement
 * @param {object} params
 * @param {number} params.seminarVenueId
 * @param {Blob} params.image
 * @returns {Promise<AxiosResponse<any>>}
 */
export const storeSeminarVenueMainImage = ({ seminarVenueId = null, image = null } = {}) => {
  const formData = new FormData();
  formData.append("modelId", seminarVenueId);
  formData.append("mainImage", image);

  return apiClient.post(ROUTE.SEMINAR_VENUES.MAIN_IMAGE.STORE, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

/**
 * Delete venue main image
 *
 * @method
 * @memberOf seminarManagement
 * @param {object} params
 * @param {object} params.mainImageId
 * @returns {Promise<AxiosResponse<any>>}
 */
export const deleteSeminarVenueMainImage = ({ mainImageId = null } = {}) =>
  apiClient.delete(ROUTE.SEMINAR_VENUES.MAIN_IMAGE.DELETE, {
    data: { modelId: mainImageId },
  });

/**
 * Store image for seminar venue
 *
 * @method
 * @memberOf seminarManagement
 * @param {object} params
 * @param {number} params.seminarVenueId
 * @param {Blob} params.image
 * @param {string} params.displayName
 * @returns {Promise<AxiosResponse<any>>}
 */
export const storeSeminarVenueImage = ({
  seminarVenueId = null,
  image = null,
  imageDisplayName = null,
} = {}) => {
  const formData = new FormData();
  formData.append("seminarVenueId", seminarVenueId);
  formData.append("image", image);
  formData.append("imageDisplayName", imageDisplayName);

  return apiClient.post(ROUTE.SEMINAR_VENUES.IMAGES.STORE, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

/**
 * Delete specified venue image
 *
 * @method
 * @memberOf seminarManagement
 * @param {object} params
 * @param {object} params.imageId
 * @param {number} params.seminarVenueId
 * @returns {Promise<AxiosResponse<any>>}
 */
export const deleteSeminarVenueImage = ({ imageId = null, seminarVenueId = null } = {}) =>
  apiClient.delete(ROUTE.SEMINAR_VENUES.IMAGES.DELETE, {
    data: { imageId, seminarVenueId },
  });

/**
 *
 * Retrieve list with seminar venues
 *
 * @method
 * @memberOf seminarManagement
 * @param {object} params
 * @param {number} params.page
 * @param {number} params.perPage
 * @param {string} params.sortBy
 * @param {boolean} params.sortDesc
 * @param {number} params.seminarVenueId
 * @returns {Promise<AxiosResponse<any>>}
 */
export const listSeminarVenueImages = (params) =>
  apiClient.get(ROUTE.SEMINAR_VENUES.IMAGES.LIST, {
    params,
  });
