<template>
  <v-app>
    <MainLoader v-if="!isInitialized" />

    <router-view v-if="!isLoading" />

    <NotificationSnackbar />
  </v-app>
</template>

<script>
import { defineComponent, nextTick, onMounted, ref } from "vue";
import NotificationSnackbar from "@components/Notifications/NotificationSnackbar/index.vue";
import MainLoader from "@components/MainLoader/index.vue";
import { mapGetters } from "vuex";
import useAppInitialization from "@use/useAppInitialization";
import store from "@/store";

export default defineComponent({
  components: { MainLoader, NotificationSnackbar },

  computed: {
    ...mapGetters("initialize", ["isInitialized"]),
  },

  setup() {
    const { initialize } = useAppInitialization();
    const isLoading = ref(true);

    onMounted(async () => {
      isLoading.value = true;

      try {
        await initialize();
      } finally {
        await store.dispatch("initialize/changeInitializationState", true);
        await nextTick(() => {
          isLoading.value = false;
        });
      }
    });

    return { isLoading };
  },
});
</script>
