const RoleUpdate = () => import("@views/Restricted/UserManagement/Role/Update");
const RoleList = () => import("@views/Restricted/UserManagement/Role/List");
const RoleShow = () => import("@views/Restricted/UserManagement/Role/Show/");
const RoleAssignPermissions = () =>
  import("@views/Restricted/UserManagement/Role/Assign/Permission");
const RoleAssignUsers = () => import("@views/Restricted/UserManagement/Role/Assign/User");
const RoleAssignUserGroups = () => import("@views/Restricted/UserManagement/Role/Assign/UserGroup");

export default [
  {
    path: "role",
    name: "ManagementRole",
    component: RoleList,
    meta: {
      permissionNames: ["role.read"],
      label: "NAVIGATION.SIDE_NAVIGATION.USER_MANAGEMENT.ROLES",
      menuNames: ["sideNavigation"],
    },
  },

  {
    path: "role/:roleId",
    name: "ManagementRoleShow",
    component: RoleShow,
    meta: { permissionNames: ["role.read"] },
  },

  {
    path: "role/:roleId/edit",
    name: "ManagementRoleUpdate",
    component: RoleUpdate,
    meta: { permissionNames: ["role.update"] },
  },

  {
    path: "role/:roleId/assign/permissions",
    name: "ManagementRoleAssignPermissions",
    component: RoleAssignPermissions,
    meta: { permissionNames: ["role.update"] },
  },

  {
    path: "role/:roleId/assign/users",
    name: "ManagementRoleAssignUsers",
    component: RoleAssignUsers,
    meta: { permissionNames: ["role.update"] },
  },

  {
    path: "role/:roleId/assign/user-groups",
    name: "ManagementRoleAssignUserGroups",
    component: RoleAssignUserGroups,
    meta: { permissionNames: ["role.update"] },
  },
];
