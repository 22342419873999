import apiClient from "@/api/modules/base";
import ROUTE from "./routes";

/**
 * Retrieve list of seminar topics
 *
 * @method
 * @memberOf seminarManagement
 * @param {object} params
 * @param {number} params.seminarId
 * @param {number} params.page
 * @param {number} params.perPage
 * @param {string} params.sortBy
 * @param {boolean} params.sortDesc
 * @param {string} params.searc
 * @returns {Promise<AxiosResponse<any>>}
 */
export const listSeminarTopics = (params) => apiClient.get(ROUTE.SEMINAR_TOPICS.LIST, { params });

/**
 * Update specified seminar topic
 *
 * @method
 * @memberOf seminarManagement
 * @param {object} params
 * @param {number} params.seminarTopicId
 * @returns {Promise<AxiosResponse<any>>}
 */
export const updateSeminarTopic = (params) => apiClient.put(ROUTE.SEMINAR_TOPICS.UPDATE, params);

/**
 * Show specified seminar topic
 *
 * @method
 * @memberOf seminarManagement
 * @param {object} params
 * @param {number} params.seminarTopicId
 * @returns {Promise<AxiosResponse<any>>}
 */
export const showSeminarTopic = ({ seminarTopicId }) =>
  apiClient.get(ROUTE.SEMINAR_TOPICS.SHOW, { params: { seminarTopicId } });

/**
 * Delete specified seminar topic
 *
 * @method
 * @memberOf seminarManagement
 * @param {object} params
 * @param {number} params.seminarTopicId
 * @returns {Promise<AxiosResponse<any>>}
 */
export const deleteSeminarTopic = ({ seminarTopicId }) =>
  apiClient.delete(ROUTE.SEMINAR_TOPICS.DELETE, { data: { seminarTopicId } });

/**
 * Create new seminar topic
 *
 * @method
 * @memberOf seminarManagement
 * @param {object} params
 * @returns {Promise<AxiosResponse<any>>}
 */
export const createSeminarTopic = (params) => apiClient.post(ROUTE.SEMINAR_TOPICS.UPDATE, params);
