import { render, staticRenderFns } from "./NavItemSecond.vue?vue&type=template&id=2d6c48b7"
import script from "./NavItemSecond.vue?vue&type=script&lang=js"
export * from "./NavItemSecond.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports