/**
 * @module vuex-authUser
 */

/**
 *
 * Store access token
 *
 * @param {object} vuex
 * @param {object} params
 * @param {string} params.accessToken
 * @param {string} params.refreshToken
 * @returns {Promise}
 */
const saveAccessToken = async ({ commit }, { accessToken, refreshToken }) => {
  commit("SAVE_ACCESS_TOKEN", {
    accessToken,
    refreshToken,
  });
};

export default saveAccessToken;
