<template>
  <v-chip label v-bind="$attrs" v-on="$listeners">
    <span class="text-caption font-weight-bold gray800--text">
      <slot />
    </span>
  </v-chip>
</template>

<script>
export default {
  name: "BaseChip",
};
</script>
