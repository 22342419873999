import apiClient from "@/api/modules/base";
import ROUTE from "./routes";

/**
 * Request contract status change
 *
 * @method
 * @memberOf seminarManagement
 * @param {object} params
 * @param {number} params.speakerContractActionId
 * @param {number} params.speakerContractId
 * @param {string} params.action
 * @param {string} params.reason
 * @param {string} params.comment
 * @returns {Promise<AxiosResponse<any>>}
 */
export const requestContractStatusChange = (params) =>
  apiClient.post(ROUTE.SEMINAR_SPEAKER_CONTRACTS.CHANGE_REQUEST.STORE, params);

/**
 * Retrieve list with contract decline reasons
 *
 * @param {object} params
 * @param {number} params.page
 * @param {number} params.perPage
 * @param {string} params.sortBy
 * @param {boolean} params.sortDesc
 * @returns {Promise<AxiosResponse<any>>}
 */
export const listContractDeclineReasons = (params) =>
  apiClient.get(ROUTE.SEMINAR_SPEAKER_CONTRACTS.CHANGE_REQUEST.REASONS_OF_CANCELATION.LIST, {
    params,
  });

/**
 *
 * @method
 * @memberOf seminarManagement
 * @param {number} speakerContractId
 * @returns {Promise<AxiosResponse<any>>}
 */
export const listContractActions = (speakerContractId) =>
  apiClient.get(ROUTE.SEMINAR_SPEAKER_CONTRACTS.CONTRACT_ACTIONS.LIST, {
    params: { speakerContractId },
  });

/**
 *
 * @method
 * @memberOf seminarManagement
 * @param {number} seminarId
 * @returns {Promise<AxiosResponse<any>>}
 */
export const listContracts = (seminarId) =>
  apiClient.get(ROUTE.SEMINAR_SPEAKER_CONTRACTS.CONTRACT.LIST, { params: { seminarId } });

/**
 *
 * @method
 * @memberOf seminarManagement
 * @param {object} payload
 * @param {number} payload.speakerContractId
 * @param {"cancel" | "confirm"} payload.action
 * @param {"health" | "covid" | "personal"} payload.reason
 * @param {?string} payload.comment
 * @param {number} payload.documentId
 * @returns {Promise<AxiosResponse<any>>}
 */
export const addContractAction = ({
  speakerContractId,
  action,
  reason,
  comment,
  documentId,
} = {}) =>
  apiClient.post(ROUTE.SEMINAR_SPEAKER_CONTRACTS.CONTRACT_ACTIONS.POST, {
    speakerContractId,
    action,
    reason,
    comment,
    documentId,
  });
