<template>
  <span>
    {{
      $t("BASE.SEMINAR_DATE", {
        startAt: dateShorter(startAt),
        endAt: dateShort(endAt),
      })
    }}
  </span>
</template>

<script>
import dateFilter from "@mixins/dateFilter";

export default {
  name: "BaseSeminarDate",

  mixins: [dateFilter],

  props: {
    startAt: {
      type: [Date, String],
      required: true,
    },

    endAt: {
      type: [Date, String],
      required: true,
    },
  },
};
</script>
