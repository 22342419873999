import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
import de from "vuetify/lib/locale/de";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    options: {
      customProperties: true,
    },
    themes: {
      light: {
        primary: "#0069B4",
        secondary: "#E30613",
        tertiary: "#FFD500",
        accent: "#82B1FF",
        info: "#0069B4",
        disabled: "#BFBFBF",
        hint: "#0069B4",
        critical: "#FF0C3E",
        criticalBackground: "#FDE7E8",
        warning: "#FFCC33",
        warningBackground: "#FDF7E6",
        warningText: "#CC9900",
        success: "#009933",
        successBackground: "#E6F5EB",
        white: "#FFFFFF",
        black: "#000000",
        error: "#FF0C3E",
        primary1000: "#0069B4",
        primary600: "#1D91E7",
        primary500: "#46A5EC",
        primary400: "#6AB5ED",
        primary300: "#93C7EE",
        primary200: "#B3D2E9",
        primary100: "#E5F0F7",
        gray1000: "#262626",
        gray900: "#484848",
        gray800: "#676767",
        gray700: "#7B7B7B",
        gray600: "#A5A5A5",
        gray500: "#C3C3C3",
        gray400: "#E0E0E0",
        gray300: "#E6E6E6",
        gray200: "#F1F1F1",
        gray100: "#F6F6F6",
        gray50: "#FBFBFB",
        topicSafety: "#99CC00",
        topicWorklaw: "#009999",
        topicDataProtection: "#FFCC33",
        topicWage: "#009933",
        topicJav: "#3366CC",
        topicMedia: "#E154AF",
        topicBr: "#FF6633",
        topicSocial: "#FF3333",
        topicHr: "#993399",
        topicEconomy: "#00CC99",
        topicSbv: "#00CCFF",
        topicAudiences: "#F51566",
        topicLanguages: "#763DDC",
        topicBrAcademy: "#009BC7",
        topicBoard: "#93999A",
        highEmphasis: "#262626",
        lowEmphasis: "#595959",
      },
    },
  },
  lang: {
    locales: { de },
    current: "de",
  },
  icons: {
    iconfont: "fa",
  },
});
