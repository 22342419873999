<template>
  <a @click.prevent="goTo" v-bind="$attrs" v-on="$listeners">
    <slot />
  </a>
</template>
<script>
import linkSwitchMethods from "@/mixins/linkSwitchMethods";
import linkSwitchProps from "@/mixins/linkSwitchProps";

export default {
  name: "BaseLinkSwitch",

  mixins: [linkSwitchMethods, linkSwitchProps],
};
</script>
