const UserDeleteRequestList = () =>
  import("@views/Restricted/UserManagement/UserDeleteRequest/List");
const UserDeleteRequestShow = () =>
  import("@views/Restricted/UserManagement/UserDeleteRequest/Show");

export default [
  {
    path: "user-delete-request",
    name: "ManagementUserDeleteRequest",
    component: UserDeleteRequestList,
    meta: {
      permissionNames: ["user.read"],
      label: "NAVIGATION.SIDE_NAVIGATION.USER_MANAGEMENT.USER_DELETE_REQUEST",
      menuNames: ["sideNavigation"],
    },
  },
  {
    path: "user-delete-request/:userDeleteRequestId",
    name: "ManagementUserDeleteRequestShow",
    component: UserDeleteRequestShow,
    meta: { permissionNames: ["user.read"] },
  },
];
