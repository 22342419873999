<template>
  <v-row class="text-h4">
    <v-row :justify="justify">
      <v-col cols="12" md="auto" v-for="(item, index) in items" :key="index">
        <v-divider v-if="item.separator">vertical</v-divider>
        <BaseNavigationSwitch v-else :nav-item="item" class="black--text clickable">
          {{ item.label }}
        </BaseNavigationSwitch>
      </v-col>
    </v-row>
  </v-row>
</template>
<script>
import useMenuItems from "@/use/useMenuItems";

export default {
  name: "main-nav-content",

  props: {
    justify: {
      type: String,
      default: "center",
    },
  },

  setup() {
    const { items } = useMenuItems({
      menuName: "mainNavigation",
      menuTypes: ["common"],
    });

    return { items };
  },
};
</script>
