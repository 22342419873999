<template>
  <span>{{ location }}</span>
</template>

<script>
import seminarTypes from "@data/seminarTypes";
import { isEmpty } from "lodash";

export default {
  name: "BaseSeminarLocation",

  data() {
    return {
      seminarLocation: null,
    };
  },

  props: {
    seminarId: {
      type: Number,
      required: true,
      default: null,
    },

    seminarTypeId: {
      type: undefined,
      required: true,
      default: null,
    },

    verbose: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  computed: {
    hasSeminarTypeId() {
      return !!this.seminarTypeId;
    },

    hasSeminarVenue() {
      return !isEmpty(this.seminarLocation);
    },

    locationNameLong() {
      const { city, zipCode, name1 } = this.seminarLocation;

      return `${name1}, in ${zipCode} ${city}`;
    },

    location() {
      const tbaLabel = this.$t("BASE.SEMINAR_LOCATION.TBA");

      if (this.hasSeminarTypeId && !this.hasSeminarVenue) {
        switch (this.seminarTypeId) {
          case seminarTypes.WEB_SEMINAR:
            return this.$t("BASE.SEMINAR_LOCATION.WEB_SEMINAR");
          case seminarTypes.BLENDED_LEARNING:
            return this.$t("BASE.SEMINAR_LOCATION.WEB_SEMINAR");
          default:
            return tbaLabel;
        }
      }

      if (this.hasSeminarTypeId && this.hasSeminarVenue) {
        switch (this.seminarTypeId) {
          case seminarTypes.PRESENCE:
            return this.verbose ? this.locationNameLong : this.seminarLocation.city;
          default:
            return tbaLabel;
        }
      }

      return tbaLabel;
    },
  },

  methods: {
    async retrieveSeminarVenue() {
      if (!this.seminarId) return;

      const { data } = await this.$axios.seminarVenue.showSeminarVenue(this.seminarId);
      this.seminarLocation = data.data;
    },
  },

  created() {
    this.retrieveSeminarVenue();
  },
};
</script>
