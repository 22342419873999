<template>
  <v-row>
    <v-col cols="8">
      <BaseDatePicker
        v-model="dateTime"
        v-bind="$attrs"
        v-on="$listeners"
        label="BASE.DATE_TIME_PICKER_LABEL_DATE"
        :required="required"
      />
    </v-col>
    <v-col cols="4">
      <BaseTimePicker
        v-model="dateTime"
        label="BASE.DATE_TIME_PICKER_LABEL_TIME"
        :required="required"
      />
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "BaseDateTimePicker",

  inheritAttrs: false,

  props: {
    value: {
      type: Date,
      required: true,
      default: new Date(),
    },

    required: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  computed: {
    dateTime: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
};
</script>
