<template>
  <v-avatar
    :id="id"
    size="36"
    class="mr-2"
    :class="{ 'avatar-padding': hasPersonData }"
    dark
    color="primary"
  >
    <span class="white--text headline" v-if="hasPersonData">{{ personData.initials }}</span>
    <v-icon v-else color="white" size="small">fas fa-user</v-icon>
  </v-avatar>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "UserInfo",
  props: {
    id: {
      type: String,
    },
  },
  computed: {
    ...mapGetters("person", ["personData", "hasPersonData"]),
  },
};
</script>

<style lang="scss" scoped>
.avatar-padding {
  padding-top: 3px;
}
</style>
