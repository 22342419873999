import * as Sentry from "@sentry/vue";
import { BrowserTracing } from "@sentry/tracing";
import APP_VERSION from "@/data/appVersion";

export default (vue, router) => {
  if (process.env.VUE_APP_SENTRY_URL) {
    Sentry.init({
      vue,
      dsn: process.env.VUE_APP_SENTRY_URL,
      integrations: [
        new BrowserTracing({
          routingInstrumentation: Sentry.vueRouterInstrumentation(router),
          tracingOrigins: [process.env.VUE_APP_SENTRY_ORIGIN, /^\//],
        }),
      ],
      tracesSampleRate: 1.0,
      environment: process.env.VUE_APP_SENTRY_ENV,
      release: `mybws@${APP_VERSION}`,
    });
  }
};
