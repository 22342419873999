<template>
  <v-text-field
    v-bind="$attrs"
    v-on="$listeners"
    v-model="filterValue"
    solo
    background-color="gray200"
    flat
    :label="labelField"
  >
    <template #append-outer>
      <slot name="append-outer" />
    </template>
  </v-text-field>
</template>

<script>
export default {
  name: "BaseFilterCombobox",

  props: {
    value: {
      type: undefined,
      required: true,
    },

    label: {
      type: String,
      required: true,
    },
  },

  computed: {
    filterValue: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },

    labelField() {
      return this.$t(this.label);
    },
  },
};
</script>
