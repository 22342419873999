<template>
  <v-row justify="center">
    <v-col cols="auto" class="text-center">
      <v-progress-circular indeterminate color="primary" />
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "BaseLoadingProgress",
};
</script>
