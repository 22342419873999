export default {
  SEMINAR_SPEAKERS: {
    LIST: "/seminar-management/management/seminar-speakers",
    SHOW: "/seminar-management/management/seminar-speakers/show",
    UPDATE: "/seminar-management/management/seminar-speakers",

    SEMINARS: {
      SHOW: "/speakers/seminar/show",
      LIST: "/speakers/seminar",
    },
  },

  IMAGES: {
    SEMINAR_SPEAKER: {
      STORE: "/seminar-management/management/images/main-image/seminar-speaker",
      DELETE: "/seminar-management/management/images/main-image/seminar-speaker",
    },
  },
};
