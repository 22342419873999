const MyContractsList = () => import("@views/Restricted/SeminarSpeaker/MyContracts/List/");
const MyContractsShow = () => import("@views/Restricted/SeminarSpeaker/MyContracts/Show/");

export default [
  {
    path: "/seminar-speaker/my-contracts",
    name: "seminar-speaker-my-contracts",
    component: MyContractsList,
    meta: {
      permissionProps: ["isSpeaker"],
      label: "NAVIGATION.SIDE_NAVIGATION.SPEAKER_CONTRACTS",
      icon: "far fa-file-contract",
      menuNames: ["sideNavigation"],
      order: -95,
    },
  },
  {
    path: "/seminar-speaker/my-contracts/seminar/:seminarId",
    name: "seminar-speaker-my-contracts-show",
    component: MyContractsShow,
    meta: { permissionProps: ["isSpeaker"] },
  },
];
