const SeminarManagement = () => import("@views/Restricted/SeminarManagement/index.vue");

const SeminarManagementList = () => import("@views/Restricted/SeminarManagement/Seminars/List");
const SeminarManagementShow = () => import("@views/Restricted/SeminarManagement/Seminars/Show");

const ListFieldOfActions = () =>
  import("@views/Restricted/SeminarManagement/SeminarFieldOfActions/List");

const ListSeminarTypeMappings = () =>
  import("@views/Restricted/SeminarManagement/SeminarTypeMappings/List");

const ListSeminarSpeakers = () =>
  import("@views/Restricted/SeminarManagement/SeminarSpeakers/List");

const ListSeminarVenues = () => import("@views/Restricted/SeminarManagement/SeminarVenues/List");
const UpdateSeminarVenues = () =>
  import("@views/Restricted/SeminarManagement/SeminarVenues/Update");

export default [
  {
    path: "/seminar-management",
    component: SeminarManagement,
    name: "SeminarManagement",
    redirect: "/seminar-management/seminars/list",
    meta: { permissionNames: ["management.seminar.read"] },
    children: [
      {
        path: "seminars/list",
        name: "SeminarManagementList",
        component: SeminarManagementList,
        meta: { permissionNames: ["management.seminar.read"] },
      },

      {
        path: "seminar-field-of-actions/list",
        name: "FieldOfActionsList",
        component: ListFieldOfActions,
        meta: { permissionNames: ["seminar_field_of_action.read"] },
      },

      {
        path: "seminar-type-mappings/list",
        name: "SeminarTypeMappingsList",
        component: ListSeminarTypeMappings,
        meta: { permissionNames: ["seminar_type_mapping.read"] },
      },

      {
        path: "seminar-speakers/list",
        name: "SeminarSpeakersList",
        component: ListSeminarSpeakers,
        meta: { permissionNames: ["seminar_speakers.read"] },
      },

      {
        path: "seminar-venues/list",
        name: "SeminarVenuesList",
        component: ListSeminarVenues,
        meta: { permissionNames: ["seminar_venue.read"] },
      },

      {
        path: "seminar-venues/:seminarVenueId/edit",
        name: "SeminarVenuesUpdate",
        component: UpdateSeminarVenues,
        meta: { permissionNames: ["seminar_venue.update"] },
      },
    ],
  },

  {
    path: "/management/seminar/show/:seminarId",
    name: "SeminarManagementShow",
    component: SeminarManagementShow,
  },
];
