<template>
  <span
    @mouseover="switchHoverState(true)"
    @mouseleave="switchHoverState(false)"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <slot />
  </span>
</template>

<script>
export default {
  name: "BaseHover",

  methods: {
    switchHoverState(state) {
      this.$emit("input", state);
    },
  },
};
</script>
